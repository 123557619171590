import {
	getIds,
	getTags,
	stringifyHtmlTranslations,
} from '@/common/code'

export default {
	_actions: {
		end: x => ({
			input: {
				...x,
			}
		})
	},
	slug: {
		default: '',
	},
	tags: {
		default: [],
		action: getTags,
	},
	clientMedias: {
		default: [],
		path: 'clientMedias.medias',
		rename: 'clientMediaIds',
		action: getIds,
	},
	htmlTranslations: {
		default: {},
		path: '_content',
		action: stringifyHtmlTranslations,
	},
}
