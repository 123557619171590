export const useCMS = {
	namespaced: true,
	state() {
		return {
			elementTypes: {
				'Client::Media': 'media',
				'Client::Section': 'section',
				'Content::Text': 'contentText',
			},
			clients: [],
		}
	},
	getters: {
		getClients: state => state.clients,
		getElementTypes: state => state.elementTypes,
	},
	mutations: {
		setClients(state, payload) {
			state.clients = payload
		},
	}
}
