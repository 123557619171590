<template>
	<div
		class="p-field"
		:class="{
			'cannot-save': cannotSave(field),
			'flash-background': flashBackground(field)
		}"
	>
		<h6>{{ fieldName.toUpperCase() }} {{ isRequired ? '*' : '' }}</h6>
		<InputSwitch
			:disabled='disabled'
			:model-value='value'
			@update:modelValue="change($event)"
		/>
		<span class='p-ml-3'>{{ description }}</span>
	</div>
</template>

<script>
import { computed } from 'vue'

export default {
	name: "TypeBoolean",
	props: {
		fieldName: {
			type: String,
			default: () => 'NO NAME FOUND...',
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
		id: {
			type: String,
			default: () => Math.random().toString(),
		},
		language: {
			type: String,
			default: () => '',
		},
		description: {
			type: String,
			default: () => '',
		},
		value: {
			type: Boolean,
			default: () => false,
		},
		changeFields: {
			type: Function,
			default: () => ({}),
		},
		field: {
			type: Object,
			default: () => ({}),
		}
	},
	setup(props) {
		const isRequired = computed(() => {
			return props.field?.needed
		})

		const change = value => {
			const { field } = props
			props.changeFields({ field, value })
		}

		const cannotSave = data =>
			!!data?._cannotSave

		const flashBackground = data =>
			data?._flashBackground?.value === true

		return {
			cannotSave,
			change,
			flashBackground,
			isRequired,
		}
	}
}
</script>

<style scoped>
.cannot-save {
	background-color: rgba(255, 0, 0, 0.25)
}

@-webkit-keyframes flash-background {
	0% {
		background-color: rgba(0, 255, 0, 0.5);
		opacity: 1;
	}
	100% {
		background-color: inherit !important;
	}
}

.flash-background {
	-webkit-animation-name: flash-background;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	-moz-animation-name: flash-background;
	-moz-animation-duration: 1000ms;
	-moz-animation-iteration-count: 1;
	-moz-animation-timing-function: ease;
}
</style>
