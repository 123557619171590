import { mutate } from '@/stores/functions'
import { getSpecificInfo } from '@/common/editor.js'
// import { reactive } from 'vue'
// import { walkThrough } from '@affordancestudio/functions'

const reset = () => ({
	editor: {},
	cards: [],
})

export const useCards = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getMedias: (state, getters, rootState, rootGetters) => rootGetters['useMedias/getMedias'],
		getCards: state => state.cards,
		// getParents: state => state.parents,
		getEditor: state => state.editor,
		getSchemaById: state => id => (state?.editor?.schemas ?? []).find(schema => schema.id === id),
	},
	mutations: {
		addCards(state, acceptedLanguages) {
			console.log(state,acceptedLanguages)
			// const newCheckpoint = reactive({
			// 	children: [],
			// 	clientMediaIds: [],
			// 	documentTranslations:
			// 		acceptedLanguages
			// 			.reduce((a, language) => ({
			// 				[language]: {},
			// 			}), {}),
			// 	id: getRandomID(10),
			// 	parentId: '',
			// 	schemaId: '',
			// 	slug: 'New-Checkpoint',
			// 	specificInfo: {
			// 		clientGroups: [],
			// 		guestExclusive: false,
			// 		onlyFor: [],
			// 		specificLanguages: [],
			// 	},
			// 	successCount: 1,
			// 	tags: [],
			// })
			// state.checkpoints = [ newCheckpoint, ...state.checkpoints ]
		},
		deleteCurrentItem(state, { id }) {
			state.cards =
				state.cards
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		async setCurrentCards(state, { payload, mediaList }) {
			const { editor, localObjects:cards } = payload
			
			if (editor.specificInfo) {
				Object.assign(editor.specificInfo, {
					...getSpecificInfo({ editor })
				})
			}
			
			state.editor = editor
			
			const medias =
				mediaList.value
					.reduce((a, c) =>
						Object.assign(a, {
							[c.id]: c,
						}), {})
			
			state.cards =
				cards
					.map(x =>
						Object.assign(x, {
							clientMedias:
								x.clientMediaIds
									.map(id => medias[id])
						}))
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
