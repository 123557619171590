<template>
	<div class='p-col-12'>
		<Panel
			toggleable
			collapsed
		>
			<template #header>
				<div style='width:100%;'>
					<Toolbar @click.stop>
						<template #start>
							<h4>Timeline Events</h4>
						</template>
						<template #end>
							<Dropdown
								:filter="true"
								v-model="refEventID"
								:options='eventList'
								class="tag-green"
								emptyMessage=""
								optionLabel='name'
								optionValue='code'
								placeholder='Select an event'
								@click.stop
							/>
							<Button
								icon='pi pi-plus'
								class='p-button-success p-mr-2'
								v-tooltip.bottom='`Add Category`'
								@click.stop='addTimelineEvent'
							/>
						</template>
					</Toolbar>
				</div>
			</template>
			<Accordion>
				<AccordionTab
					v-for='node of timelineEventList'
					:key='`${refMeta.elementType}-${node.id}`'
				>
					<template #header>
						<div style='width:100%;'>
							<Toolbar @click.stop>
								<template #start>
									<div style="width:250px;">
										<Tag
											class='tag-black'
										>{{ node.event.slug }}</Tag>
									</div>

									<div class="p-card p-mr-2">
										<div
											class="p-card-body"
											@click.stop
										>
											<div style="display:flex;flex-direction:row;">
											<Tag class="tag-black p-mr-2">Origin</Tag>
											<Dropdown
												:filter="true"
												:model-value="node.originId"
												:options='origins(refData, node.id)'
												class="tag-green"
												emptyMessage=""
												optionLabel='name'
												optionValue='code'
												placeholder='Select an origin'
												@update:modelValue="changeOrigin(node, $event)"
												@click.stop
											/>
											</div>
										</div>
									</div>
								</template>
								<template #end>
									<Button
										icon='pi pi-trash'
										class='p-button-success p-mr-2'
										v-tooltip.bottom='`Delete`'
										@click.stop='confirmDelete(node)'
									/>
									<Button
										icon='pi pi-save'
										class='p-button-success p-mr-2'
										v-tooltip.bottom='`Save Timeline Event`'
										@click.stop='save(node)'
									/>
								</template>
							</Toolbar>
						</div>
					</template>

					<div
						v-for='(_, fieldName) of node'
						:key="`${fieldName}-${node.id}`"
						class="p-grid p-fluid"
					>
						<div class="p-col-12">
							<component
								:is="fieldName"
								:meta="refMeta"
								:field-name="fieldName"
								:schemaId="node.schemaId"
								v-if="isComponent(fieldName)"
								v-model="node[fieldName]"
								@update:modelValue="onChange(node, fieldName, $event)"
								@schemaId="schemaId"
							/>
						</div>
					</div>
				</AccordionTab>
			</Accordion>
		</Panel>
	</div>
</template>

<script>
import { computed, inject, ref, watchEffect } from 'vue'
import {
	elementDeleted,
	entries,
	getRenamedProperties,
	isComponent,
	isDocumentTranslations, toastError,
} from '@/common/code'
import { usePinia } from '@/stores/pinia/main'
import {getRandomID, sortOnRelation, timeUnitsFromSeconds} from '@affordancestudio/functions'
import { storeToRefs } from 'pinia'
import { changeProperty } from '@/common/code'
import {structures} from '@/components/cms/structures/structures'
import {useToast} from 'primevue/usetoast'
import {confirmDeleteElement} from '@/common/crud/delete'
import {useConfirm} from 'primevue/useconfirm'
import {cloneDeep} from 'lodash'
import {saveItem} from '@/common/crud/save'

export default {
	name: 'timelineEvents',
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		node: {
			type: Object,
			default: () => ({}),
		},
	},
	emits:[ 'update:modelValue', 'schemaId' ],
	setup(props, { emit }) {
		//	*******************************************************************************************
		//	INJECT
		//	*******************************************************************************************
		const boxPost = inject('$boxPost')

		//	*******************************************************************************************
		//	USE
		//	*******************************************************************************************

		const pinia = usePinia()
		const toast = useToast()
		const confirm = useConfirm()

		//	*******************************************************************************************
		//	CONSTANTS
		//	*******************************************************************************************

		const { get } = pinia
		const { getData, getMeta } = storeToRefs(pinia)
		const { acceptedLanguages } = get('client')?.setting
		const fields = [ 'delta', 'interval' ]
		const CONFIRM_DIALOG_GROUP = 'event'
		const DELETE_CLIENT_TIMELINE_EVENT = 'deleteClientTimelineEvent'
		const CREATE_CLIENT_TIMELINE_EVENT = 'createClientTimelineEvent'
		const UPDATE_CLIENT_TIMELINE_EVENT = 'updateClientTimelineEvent'

		//	*******************************************************************************************
		//	REF
		//	*******************************************************************************************

		const refData = ref({})
		const refEventID = ref(null)
		const isSaving = ref(false)

		//	*******************************************************************************************
		//	COMPUTED
		//	*******************************************************************************************

		const eventList = computed(() => {
			return getData.value('clientEventIndex')
				.map(({ id:code, slug:name, elementType }) => ({
					code,
					name,
					elementType,
				}))
		})

		const clientIdentifier = computed(() => get('client')?.identifier)
		const refMeta = computed(() => getMeta.value('clientEventIndex'))
		const timelineEventList = computed(() => {
			const array =
				[ ...refData.value.data ]
					.sort((a, b) => String(a.originId ?? '').localeCompare(b.originId ?? ''))
			const primaryKey = 'id'
			const foreignKey = 'originId'
			return sortOnRelation({ array, primaryKey, foreignKey })
		})

		const schemas = computed(() =>
			refMeta?.value?.editor?.schemas
				? getRenamedProperties(refMeta.value.editor.schemas, { id: 'code', slug: 'name' })
				: []
		)

		//	*******************************************************************************************
		//	WATCH
		//	*******************************************************************************************

		watchEffect(() => refData.value = props.modelValue)

		//	*******************************************************************************************
		//	FUNCTIONS
		//	*******************************************************************************************

		const addTimelineEvent = () => {
			const data = {
				delta: 0,
				elementType: 'Client::Timeline::Event',
				event: {
					id: refEventID.value,
				},
				id: getRandomID(10),
				interval: 0,
				originId: '',
				timelineId: props.node.id,
			}
			save(data)
		}

		const changeOrigin = (node, originId) => {
			const { delta, elementType, event, id, interval } = node
			const data = {
				delta,
				elementType,
				event,
				id,
				interval,
				originId,
			}
			save(data)
		}

		const origins = (node, id) => {
			return node.data
				.filter(({ id:ID }) => id !== ID)
				.map(({ id:code, event }) => {
					const { slug:name } = event
					return {
						code,
						name,
					}
				})
		}

		const confirmDelete = data => {
			const value = cloneDeep(data)
			const client = clientIdentifier.value
			const funcEnd = elementDeleted({ toast })
			const funcRefresh = () => {
				pinia.addRefresh('Event')
				pinia.addRefresh('Timeline')
				pinia.refresh({ client })
			}
			const group = CONFIRM_DIALOG_GROUP
			const mutation = DELETE_CLIENT_TIMELINE_EVENT

			const { event = {} } = value
			const { slug } = event

			if (slug) Object.assign(value, { slug })

			confirmDeleteElement({
				value,
			},
			{
				boxPost,
				client,
				confirm,
				extra: { timelineId: props.node.id },
				funcEnd,
				funcRefresh,
				group,
				mutation,
			})
		}

		const save = data => {
			const { elementType, event } = data
			const { id:eventId } = event
			const { id:timelineId } = props.node
			Object.assign(data, {
				eventId,
				timelineId,
			})
			const model = elementType.split('::').join('')
			const structure = structures[model]
			const client = clientIdentifier.value
			const mutations = {
				create: CREATE_CLIENT_TIMELINE_EVENT,
				update: UPDATE_CLIENT_TIMELINE_EVENT,
			}

			if (!structure) {
				toast.add({
					severity: 'error',
					summary: 'Save item',
					detail: 'No data structure found',
					life: 4000,
				})
				return
			}

			saveItem({
				client,
				data,
				mutations,
				structure,
			},
			{
				refresh: () => {
					pinia.addRefresh('Event')
					pinia.addRefresh('Timeline')
					pinia.refresh({ client })
				},
				boxPost,
				toast,
				toastError: toastError({ toast }),
			})

			isSaving.value = false
		}

		const onChange = (element, fieldName, value) => {
			// debugger
			Object.assign(element, {
				[fieldName]: value,
			})
			// debugger
			emit('update:modelValue', refData.value)
		}

		const displayTimeUnits = value =>
			entries(timeUnitsFromSeconds(value))
				.reduce((a, [ unit, value ]) =>
					`${a}${ a ? ' ' : ''} ${value}${unit}`
				, '')

		const updateTimelineTimeUnits = (object, field, value) => {
			Object.assign(object, {
				[field]: value,
			})
		}

		const schemaId = schemaId => {
			emit('schemaId', schemaId)
		}

		//	*******************************************************************************************
		//	RETURN
		//	*******************************************************************************************

		return {
			CONFIRM_DIALOG_GROUP,
			addTimelineEvent,
			refEventID,
			eventList,
			timelineEventList,
			acceptedLanguages,
			changeOrigin,
			changeProperty,
			confirmDelete,
			displayTimeUnits,
			fields,
			isComponent,
			isDocumentTranslations,
			onChange,
			origins,
			refData,
			refMeta,
			save,
			schemaId,
			schemas,
			updateTimelineTimeUnits,
		}
	}
}
</script>

<style scoped lang='scss'>
.tag-black {
	background-color: #333;
}
.tag-field {
	font-size: 1.1rem;
}
.tag-language {
	font-size: 1.2rem;
}
.tag-blue {
	background-color: #458df1;
	font-size: 1.1rem;
}

.tag-green {
	background-color: #69a138;
}

.p-multiselect {
	width: 100%;
}

::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);

		img.flag {
			width: 17px;
		}
	}
}

@media screen and (max-width: 640px) {
	.p-multiselect {
		width: 100%;
	}
}

.card {
	background: #ffffff;
	padding: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
	border-radius: 4px;
	margin-bottom: 2rem;
}
.p-dropdown {
	width: 14rem;
	font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	img {
		width: 50px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid var(--surface-border);

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}

.image-container {
	&.audio {
		width: 100%;
	}

	&.tiny {
		width: 200px;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 180px;
			height: 50px;
		}

		> .lottie {
			width: 100px;
			height: 50px;
		}

		> .pdf {
			width: 100px;
			height: 100px;
		}
	}
	&.small {
		width: 300px;
		height: 200px;
		margin-right: 1rem;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 200px;
			max-height: 200px;
		}

		> .lottie {
			width: 200px;
			height: 200px;
		}

		> .pdf {
			width: 200px;
			height: 200px;
		}
	}

	&.big,
	&.galleria {
		width: 100%;
		height: 300px;

		>.image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #eee;
		}

		> .lottie {
			width: 100%;
			height: 100%;
		}

		> .pdf {
			width: 100%;
			height: 100%;
		}
	}

	&.zoomed {
		width: 100%;
		height: 100%;

		> .image {
			background-color: #eee;
			width: 100%;
			height: 100%;
		}

		> .lottie {
			height: 100%;
		}

		> .pdf {
			width: 400px;
			height: 600px;
		}
	}
}
.schema-dropdown {
	display: flex;
	align-items: center;
}
</style>
