<template>
	<div
		class='p-field'
		:class="{
			'cannot-save': cannotSave(field),
			'flash-background': flashBackground(field)
		}"
	>
		<h6>{{ fieldName.toUpperCase() }} {{ isRequired ? '*' : '' }}</h6>
		<Editor
			:placeholder='description'
			v-model='editorValue'
			@text-change="change"
		>
			<template #toolbar>
				<span class='ql-formats'>
					<select class='ql-font' />
					<select class='ql-size' />
				</span>
				<span class='ql-formats'>
					<button class='ql-bold'></button>
					<button class='ql-italic'></button>
					<button class='ql-underline'></button>
				</span>
				<span class='ql-formats'>
					<select class='ql-color' />
					<select class='ql-background' />
				</span>
				<span class='ql-formats'>
					<button class='ql-list' value='ordered' />
					<button class='ql-list' value='bullet' />
					<button class='ql-indent' value='-1' />
					<button class='ql-indent' value='+1' />
				</span>
				<span class='ql-formats'>
					<select class='ql-align' />
				</span>
				<span class='ql-formats'>
					<button class='ql-link' />
				</span>
				<span class='ql-formats'>
					<button class='ql-clean' />
				</span>
				<span class='ql-formats'>
					<button
						class='pi pi-save'
						@click='change'
					/>
				</span>
			</template>
		</Editor>
	</div>
</template>

<script>
import Editor from 'primevue/editor'
import { computed, ref, watchEffect } from 'vue'

export default {
	name: 'TypeEditor',
	components: {
		Editor,
	},
	props: {
		fieldName: {
			type: String,
			default: () => 'NO NAME FOUND...',
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
		id: {
			type: String,
			default: () => Math.random().toString(),
		},
		language: {
			type: String,
			default: () => '',
		},
		description: {
			type: String,
			default: () => '',
		},
		modelValue: {
			type: String,
			default: () => '',
		},
		changeFields: {
			type: Function,
			default: () => {
			},
		},
		field: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const isRequired = computed(() => {
			return props.field?.needed
		})

		const editorValue = ref('')

		watchEffect(() => editorValue.value = props.modelValue)

		const change = () => {
			const value = editorValue.value
			const { field } = props
			props.changeFields({ field, value })
			emit('update:modelValue', value)
		}

		const cannotSave = data =>
			!!data?._cannotSave

		const flashBackground = data =>
			data?._flashBackground?.value === true

		return {
			cannotSave,
			change,
			editorValue,
			flashBackground,
			isRequired,
		}
	},
}
</script>

<style scoped>
.cannot-save {
	background-color: rgba(255, 0, 0, 0.25)
}

@-webkit-keyframes flash-background {
	0% {
		background-color: rgba(0, 255, 0, 0.5);
		opacity: 1;
	}
	100% {
		background-color: inherit !important;
	}
}

.flash-background {
	-webkit-animation-name: flash-background;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	-moz-animation-name: flash-background;
	-moz-animation-duration: 1000ms;
	-moz-animation-iteration-count: 1;
	-moz-animation-timing-function: ease;
}
</style>
