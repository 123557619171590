import { mutate } from '@/stores/functions'
// import { store } from './vuex.js'
// import { isEmpty } from 'lodash'

const reset = () => ({
	currentSections: [],
	newSections: [],
	newTags: [],
})

export const useSections = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getSections: state => state.currentSections ?? [],
		getModelMetaTags: state => state.editor?.tags?.value ?? {},
		getNewSections: state => state.newSections,
		getNewTags: state => state.newTags,
	},
	mutations: {
		deleteCurrentItem(state, { id }) {
			// debugger
			state.currentContentTexts =
				state.currentContentTexts
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		setSelectedSectionsSettings(state, { field, path, value }) {
			field[path] = value
		},
		async setCurrentSections(state, { payload, emit }) {
			const { editor, localObjects } = payload

			if (!editor) return

			state.editor = editor
			state.currentSections = localObjects
			let counter = 0
			let message = 'Sections transformation'
			const total = state.currentSections.length
			for (const section of state.currentSections) {
				counter += 1
				emit({ message, counter, total })
				const tags =
					section.tags
						.map(tag => ({
							tag,
							code: tag,
						}))
				Object.assign(section, {
					tags,
				})
			}
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
