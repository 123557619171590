import { compose } from '@affordancestudio/functions'
import {
	entries,
	keys,
	mapName,
	mapNameCode,
	sortByName,
} from '@/common/code'

export const spreadNameCode = compose(sortByName, mapNameCode, entries)
export const spreadName = compose(sortByName, mapName, keys)
