export const useMiscellaneous = {
	namespaced: true,
	state() {
		return {
			miscellaneous: {}
		}
	},
	getters: {
		getMiscellaneous: state => ({ object }) => state.miscellaneous[object],
	},
	mutations: {
		setMiscellaneous(state, { object, value }) {
			state.miscellaneous[object] = value
		}
	},
}
