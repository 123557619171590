<template>
	<OrderList
		v-model='elements'
		data-key='id'
	>
		<template #item='{item}'>
			<Tag class="tag-black">{{item.slug}}</Tag>
		</template>
	</OrderList>
</template>

<script>

import {computed} from 'vue'

export default {
	name: 'ContentTexts',
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const elements = computed({
			get:() => props.modelValue,
			set: value => emit('update:modelValue', value)
		})
		return {
			elements,
		}
	}
}
</script>

<style scoped lang='scss'>
.tag-black {
	background-color: #333;
}
::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}
}
.image-container {
	&.audio {
		width: 100%;
	}

	&.tiny {
		width: 200px;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 180px;
			height: 50px;
		}

		> .lottie {
			width: 100px;
			height: 50px;
		}

		> .pdf {
			width: 100px;
			height: 100px;
		}
	}
	&.small {
		width: 300px;
		height: 200px;
		margin-right: 1rem;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 200px;
			max-height: 200px;
		}

		> .lottie {
			width: 200px;
			height: 200px;
		}

		> .pdf {
			width: 200px;
			height: 200px;
		}
	}

	&.big {
		width: 100%;
		height: 300px;

		>.image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #eee;
		}

		> .lottie {
			width: 100%;
			height: 100%;
		}

		> .pdf {
			width: 100%;
			height: 100%;
		}
	}

	&.zoomed {
		width: 100%;
		height: 100%;

		> .image {
			background-color: #eee;
			width: 100%;
			height: 100%;
		}

		> .lottie {
			height: 100%;
		}

		> .pdf {
			width: 400px;
			height: 600px;
		}
	}
}
</style>
