<template>
	<div class="p-col-3">
		<TimeUnits
			:field="fieldName"
			v-model="refValue"
			@update:modelValue="onChange"
		/>
	</div>
</template>

<script>
import TimeUnits from '@/components/cms/innerComponents/TimeUnits'
import { ref, watchEffect } from 'vue'

export default {
	name: 'interval',
	props: {
		fieldName: {
			type: String,
			default: () => '',
		},
		modelValue: {
			type: Number,
			default: () => 0,
		}
	},
	components: {
		TimeUnits,
	},
	setup(props, { emit }) {
		const refValue = ref()

		watchEffect(() => refValue.value = props.modelValue)

		const onChange = value => {
			emit('update:modelValue', value)
		}

		return {
			onChange,
			refValue
		}
	}
}
</script>

<style scoped>

</style>
