import { mutate } from '@/stores/functions'
import { cloneDeep, isObject } from 'lodash'
import { getRandomID } from '@affordancestudio/functions'

const reset = () => ({
	editor: {},
	treeSchemas: [],
	newSchemas: [],
	newTags: [],
	models: [],
	subjects: [],
})

export const useSchemas = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getModelMetaTags: state => state.editor?.tags?.value ?? {},
		getModels: state => state.models,
		getNewSchemas: state => state.newSchemas,
		getNewTags: state => state.newTags,
		getTreeSchemas: state => state.treeSchemas,
	},
	mutations: {
		deleteCurrentItem(state, { id }) {
			// debugger
			state.currentContentTexts =
				state.currentContentTexts
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		setSelectedSectionsSettings(state, { field, path, value }) {
			field[path] = value
		},
		async setModels(state, { payload }) {
			state.models = payload
		},
		async setSubjects(state, { payload }) {
			state.subjects = payload
		},
		async setCurrentSchemas(state, { payload }) {
			const { editor, localObjects } = payload

			if (!editor) return

			state.editor = editor

			const getChildren = subjectsToH => {
				return Object.entries(subjectsToH)
					.reduce((a, [ slug, data ]) => {
						const { id:key } = data
						let children = []

						if (!key && isObject(data)) {
							children =
								Object.entries(data)
									.reduce((a, [ slug, value ]) => {
										delete data[slug]
										return [
											...a,
											...getChildren({ [slug]: value }),
										]
									}, [])
						}

						const object = {
							key: key ?? getRandomID(),
							data: Object.assign(data, { slug })
						}

						if (children.length) Object.assign(object, { children })

						return [
							...a,
							object,
						]
					}, [])
			}

			state.treeSchemas =
				localObjects
					.sort((a, b) => a.slug.localeCompare(b.slug))
					.map(schema => {
						const object = cloneDeep(schema)
						const { subjectsToH } = object

						const children =
							getChildren(subjectsToH)
								.sort((a, b) => a.data.slug.localeCompare(b.data.slug))

						delete object.subjectsToH

						return {
							...object,
							children,
						}
					})
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
