import { mutate } from '@/stores/functions'
import { cloneDeep, uniqBy } from 'lodash'
import { getRandomID } from '@affordancestudio/functions'
import { documentTransform } from '@/common/code'

const newMediaKeys = state => {
	state.newMedias = state.newMedias
		.map((x, key) => Object.assign(x, {
			key
		}))
}

const setSettingsValue = ({ state, setting = {} }) => {
	const BEFORE_START = 'before_start'
	const IN_BACKGROUND = 'in_background'
	// const ON_DEMAND = 'on_demand'
	
	const _setting = state?.editor?.setting

	if (!_setting) return setting

	const { prefetch, preload } = setting
	let loadingFile
	if (preload) loadingFile = BEFORE_START
	if (prefetch) loadingFile = IN_BACKGROUND
	if (!preload && !prefetch) loadingFile = IN_BACKGROUND
	return {
		loadingFile,
	}
}

const reset = () => ({
	currentMedias: [],
	distributedMedias: [],
	editedMedia: null,
	editor: null,
	newMedias: [],
	newTags: [],
	selectedMediasSchemaId: null,
	selectedMediasState: null,
	selectedMediasSetting: null,
	settings: null,
})

export const useMedias = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getDistributedMedias: state => state.distributedMedias,
		getEditedMedia: state => state.editedMedia,
		getMedias: state => state.currentMedias,
		getModelMetaTags: state => state.editor?.tags?.value ?? {},
		getNewMedias: state => state.newMedias,
		getNewTags: state => state.newTags,
		getSchemaById: state => id => (state?.editor?.schemas ?? []).find(schema => schema.id === id),
		getSchemas: state => state.editor?.schemas ?? [],
		getSelectedMediasSchemaId: state => state.selectedMediasSchemaId,
		getSelectedMediasSetting: state => state.selectedMediasSetting ?? [],
		getSelectedMediasState: state => state.selectedMediasState,
		getSetting: state => state?.editor?.setting ?? {},
		getStates: state => state.editor?.state ?? [],
		getUndoMutation: state => state.undoMutation,
	},
	mutations: {
		clearStoreMedias(state) {
			state.currentMedias = []
		},
		setDistributedMedias(state, payload) {
			state.distributedMedias = payload
		},
		resetSelectedMediasSchemaId(state) {
			state.selectedMediasSchemaId = null
		},
		resetSelectedMediasState(state) {
			state.selectedMediasState = null
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		setSettings(state, { settings }) {
			state.editor.setting = settings
		},
		setCurrentMedias(state, { payload, acceptedLanguages }) {
			const { currentMedias = [], editor } = payload

			if (!editor) return

			state.editor = editor
			state.setting = editor.setting

			const editorSchemas =
				state.editor.schemas
					.reduce((a, c) => Object.assign(a, {
						[c.id]: c,
					}), {})

			state.currentMedias = [
				...currentMedias
					.map(media => {
						const {
							documentTranslations,
							medias,
							schemaId,
							tags,
						} = media
						return Object.assign(media, {
							schema: editorSchemas[schemaId],
							medias: Object.entries(medias)
								.reduce((a, [ language, c ]) => {
									const { blob } = c
									return Object.assign(a, {
										[language]: Object.assign(c, {
											objectURL: URL.createObjectURL(blob),
											size: blob.size,
										})
									})
								}, {}),
							documentTranslations:
								documentTransform({
									acceptedLanguages,
									documentTranslations,
									schema: editorSchemas[schemaId] }),
							// documentTranslations0: Object.keys(documentTranslations)
							// 	.reduce((a, language) => {
							// 		return Object.assign(a, {
							// 			[language]: Object.keys(subjectsToH)
							// 				.reduce((a, field) => {
							// 					return Object.assign(a, {
							// 						[field]: Object.assign(cloneDeep(subjectsToH[field]), {
							// 							value: cloneDeep((((documentTranslations ?? {})[language] ?? {})[field] ?? null))
							// 						})
							// 					})
							// 				}, {})
							// 		})
							// 	}, {}),
							tags: tags
								.map(tag => ({
									tag,
									code: tag,
								}))
						})
					})
			]
		},
		setMedia(state, payload) {
			state.media = payload
		},
		addNewMedias(state, { payload }) {
			state.newMedias = [
				...state.newMedias,
				...payload
			]
		},
		addMediasWithoutLanguageSuffix(state, { payload, selectedLanguagesAddMedias }) {
			state.distributedMedias = [
				...state.distributedMedias,
				...payload
					.map(media => ({
						isNew: true,
						slug: media.name.split('.').shift().replaceAll('_', '-'),
						id: media.id,
						schemaId: null,
						medias: selectedLanguagesAddMedias.value
							.reduce((a, language) => Object.assign(a, {
								[language]: media
							}), {}),
						setting: setSettingsValue({ state }),
					}))
			]
		},
		addMediasWithLanguageSuffix(state, { payload }) {
			const newMedias = payload
				.reduce((a, media) => {
					const { name } = media
					const split = name.split('.').shift().split(/[\s_-]/)
					const language = split.pop()
					const id = split.join('-')
					if (!a[id]) a[id] = {}
					Object.assign(a[id], {
						isNew: true,
						id: media.id,
						slug: id,
						schemaId: null,
						setting: setSettingsValue({ state }),
						medias: Object.assign(a[id]?.medias ?? {}, {
							[language]: media
						})
					})
					return a
				}, {})
			state.distributedMedias =
				[
					...state.distributedMedias,
					...Object.entries(newMedias)
						.reduce((a, [, data ]) => [ ...a, data ], [])
				]
		},
		distributeCurrentMedias(state, { payload, schemaId = null, slug = '' }) {
			// debugger
			let tags = []
			const id = getRandomID()
			const media = {
				isNew: true,
				id,
				medias: payload
					.reduce((a, media) => {
						const { tags:tag } = media
						tags =
							uniqBy([
								...tags,
								...tag,
							], 'tag')
						return Object.assign(a, {
							[media.pickListLanguage]: media
						})
					}, {}),
				schemaId,
				setting: setSettingsValue({ state }),
			}
			if (slug) Object.assign(media, { slug })
			if (tags.length) Object.assign(media, { tags })
			state.distributedMedias = [
				...state.distributedMedias,
				media,
			]
		},
		distributeNewMedias(state, payload) {
			// debugger
			const id = Math.random().toString()
			state.distributedMedias = [
				...state.distributedMedias,
				{
					isNew: true,
					id,
					medias: payload
						.reduce((a, media) =>
							Object.assign(a, {
								[media.pickListLanguage]: media
							}), {}),
					schemaId: null,
					setting: setSettingsValue({ state }),
				}
			]
		},
		setNewMedias(state, payload) {
			state.newMedias = payload
			// const ids =
			// 	payload
			// 		.map(({ id }) => id)
			// state.newMedias = [
			// 	...state
			// 		.newMedias
			// 		.filter(({ id }) => !ids.includes(id)),
			// 	...payload
			// ]
			newMediaKeys(state)
		},
		deleteCurrentMedia(state, { id:_id }) {
			state.currentMedias =
				state.currentMedias
					.filter(({ id }) => id !== _id)
		},
		deleteNewMedias(state, payload) {
			const keys = payload.map(({ key }) => key)
			state.newMedias = state.newMedias
				.filter(({ key }) => !keys.includes(key))
			newMediaKeys(state)
		},
		deleteDistributedMedias(state, { ids }) {
			state.distributedMedias =
				state.distributedMedias
					.filter(({ id }) => !ids.includes(id))
		},
		setSelectedMediaState(state, payload) {
			state.selectedMediasState = payload
		},
		setSelectedMediaSchemaId(state, payload) {
			state.selectedMediasSchemaId = payload
		},
		setSelectedMediaSetting(state, payload) {
			// debugger
			state.selectedMediasSetting = payload
		},
		setMediaSchemaId(state, { id, schemaId }) {
			state.newMedias = state.newMedias
				.map(x =>
					x.id === id
						? Object.assign(x, { schemaId })
						: x
				)
			newMediaKeys(state)
		},
		setEditedMedia(state, { id:_id }) {
			const media =
				state.currentMedias
					.find(({ id }) => id === _id)
			const { tags = [] } = media
			state.editedMedia = cloneDeep(media)
			const { setting:settings } = state.editedMedia
			Object.assign(state.editedMedia, {
				settings: setSettingsValue({ state, settings }),
				tags: tags
					.map(tag => ({
						tag,
						code: tag,
					}))
			})
		},
		mutate(state, {
			_extra,
			field,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		assignDistributed({ state }) {
			state.newMedias
				.forEach(media => {
					state.distributedMedias[media.id] = state.distributedMedias[media.id] ?? {}
					state.distributedMedias[media.id].medias = state.distributedMedias[media.id].medias ?? {}
					state.distributedMedias[media.id].medias[media.language] = media
				})
			state.newMedias = []
		},
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	}
}
