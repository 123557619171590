<template>
    <div class="pages-body contact-page p-d-flex p-flex-column">
        <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
            <div class="topbar-left p-ml-3 p-d-flex" @click="goDashboard">
                <div class="logo">
                    <img src="assets/layout/images/logo2x.png" alt=""/>
                </div>
            </div>
            <div class="topbar-right p-mr-3 p-d-flex">
                <Button type="button" label="DASHBOARD" @click="goDashboard"
                        class="p-button-text p-button-plain topbar-button"></Button>
            </div>
        </div>

        <div class="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9746.74079475134!2d4.8686484347131325!3d52.35799458335991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609e2c5b47ccf%3A0x6cd478550520ca35!2sVondelpark!5e0!3m2!1str!2str!4v1606820468794!5m2!1str!2str"
                aria-hidden="false" tabindex="0" allowtransparency="true"></iframe>
            <span></span>
        </div>

        <div class="pages-panel">
            <div class="card p-grid">
                <div class="left-panel p-lg-6 p-md-6 p-sm-12">
                    <h4 class="p-mb-3">Contact Us</h4>
                    <p class="p-mb-6">Subheader of contact us will come here...</p>
                    <InputText type="text" class="contact-input p-mb-3"  placeholder="Your Name" />
                    <InputText type="text" class="contact-input p-mb-3" InputText placeholder="Your E-mail" />
                    <Textarea class="contact-input contact-message p-mb-6" rows="8" pInputTextarea
                              placeholder="Your Message"></Textarea>
                    <Button class="p-mb-3" label="SEND MESSAGE"></Button>
                </div>
                <div class="right-panel p-lg-6 p-md-6 p-sm-12 p-mx-auto">
                    <div class="p-grid">
                        <div class="p-md-offset-3 p-md-6 p-md-offset-3 p-sm-offset-2 p-sm-8 p-sm-offset-2">
                            <i class="pi pi-home p-mb-2 p-mt-5"></i>
                            <p class="title p-my-3">Headquarters</p>
                            <p>Van Beekweg 79-93 7825VC Eexterzandvoort</p>

                            <i class="pi pi-briefcase p-mb-2 p-mt-5"></i>
                            <p class="title p-my-3">Sales Office</p>
                            <p>De Roodreef 89k 8502CA Oosternijkerk</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
	methods: {
		goDashboard(){
			window.location = "/#/"
		}
	}
}
</script>
