import ClientCategory from './ClientCategory'
import ClientEvent from './ClientEvent'
import ClientTimeline from './ClientTimeline'
import ClientTimelineEvent from './ClientTimelineEvent'
import ContentText from './ContentText'

export const structures = {
	ClientCategory,
	ClientEvent,
	ClientTimeline,
	ClientTimelineEvent,
	ContentText,
}

export const isStructure = elementType =>
	!!structures[elementType]
