import { transform } from '@/common/transform/transform'
import { saveItem } from '@/common/crud/save'
import { toastError } from '@/common/code'
import {structures} from '@/components/cms/structures/structures'
import {usePinia} from '@/stores/pinia/main'
import {storeToRefs} from 'pinia'

export const transformDataField = ({ value }) => {
	return transform(value)
}

export const saveElement = ({ boxPost, refresh, taskPost, toast }) => ({ value:data, memory }) => {
	const { relatedData } = memory
	const { elementType } = data
	const model = elementType.split('::').join('')
	const structure = structures[model]
	const mutations = {
		create: `create${model}`,
		update: `update${model}`,
	}
	const pinia = usePinia()
	const { get } = storeToRefs(pinia)
	const client = get.value('client')?.identifier
	
	saveItem({
		client,
		data:
			relatedData
				? {
					...data,
					...relatedData,
				}
				: data,
		mutations,
		structure,
	},
	{
		boxPost,
		refresh,
		taskPost,
		toast,
		toastError: toastError({ toast }),
	})
	
	return memory
}
