<template>
	<div
		class="p-field"
		:class="{
			'cannot-save': cannotSave(field),
			'flash-background': flashBackground(field)
		}"
	>
		<h6>{{ fieldName.toUpperCase() }} {{ isRequired ? '*' : '' }}</h6>
		<InputNumber
			:disabled='disabled'
			:placeholder='field.description'
			v-model="editValue"
		/>
	</div>
</template>

<script>
import { computed, ref, watch, watchEffect } from 'vue'

export default {
	name: "TypeInteger",
	props: {
		fieldName: {
			type: String,
			default: () => 'NO NAME FOUND...',
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
		field: {
			type: Object,
			default: () => ({}),
		},
		modelValue: {
			type: Number,
			default: () => 0,
		}
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const editValue = ref(null)

		const isRequired = computed(() => {
			return props.field?.needed
		})

		const cannotSave = data =>
			!!data?._cannotSave

		const flashBackground = data =>
			data?._flashBackground?.value === true

		watchEffect(() => {
			editValue.value = props.modelValue
		})

		watch(
			() => editValue.value,
			value => {
				emit('update:modelValue', value)
			}
		)

		return {
			cannotSave,
			editValue,
			flashBackground,
			isRequired,
		}
	}
}
</script>

<style scoped>
.cannot-save {
	background-color: rgba(255, 0, 0, 0.25)
}

@-webkit-keyframes flash-background {
	0% {
		background-color: rgba(0, 255, 0, 0.5);
		opacity: 1;
	}
	100% {
		background-color: inherit !important;
	}
}

.flash-background {
	-webkit-animation-name: flash-background;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	-moz-animation-name: flash-background;
	-moz-animation-duration: 1000ms;
	-moz-animation-iteration-count: 1;
	-moz-animation-timing-function: ease;
}
</style>
