import { mutate } from '@/stores/functions'
import { medias } from '@affordancestudio/engage-cms'
import { store } from './vuex.js'
import {getNewElement} from '@/common/structures'
import {spreadName, spreadNameCode} from '@/common/composition'
// import { isEmpty } from 'lodash'

const reset = () => ({
	currentContentTexts: [],
	editor: {},
	newContentTexts: [],
	newTags: [],
})

export const useContentTexts = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getCurrentContentTexts: state => state.currentContentTexts,
		getEditor: state => state.editor,
		getMedias: (state, getters, rootState, rootGetters) => rootGetters['useMedias/getMedias'],
		getModelMetaTags: state => state.editor?.tags?.value ?? {},
		getNewContentTexts: state => state.newContentTexts,
		getNewTags: state => state.newTags,
	},
	mutations: {
		deleteCurrentItem(state, { id }) {
			state.currentContentTexts =
				state.currentContentTexts
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		setSelectedContentTextSettings(state, { field, path, value }) {
			field[path] = value
		},
		addContentText(state) {
			const contentText = getNewElement('contentTextIndex', '00000000')
			state.currentContentTexts = [
				contentText,
				...state.currentContentTexts,
			]
		},
		async setCurrentContentTexts(state, { payload, emit = () => {} }) {
			const { editor, localObjects } = payload
			
			Object.assign(editor.specificInfo.clientGroups, { value: spreadNameCode(editor.specificInfo.clientGroups.value) })
			Object.assign(editor.specificInfo.onlyFor, { value: spreadName(editor.specificInfo.onlyFor.value) })
			Object.assign(editor.specificInfo.alternatives, { value: spreadName(editor.specificInfo.alternatives.value) })
			Object.assign(editor.specificInfo.specificLanguages, { value: spreadName(editor.specificInfo.specificLanguages.value) })
			
			const { specificInfo:SPECIFIC_INFO_EDITOR } = editor
			const { clientGroups:CLIENT_GROUPS_EDITOR } = SPECIFIC_INFO_EDITOR
			const clientGroupsCodes =
				CLIENT_GROUPS_EDITOR.value
					.reduce((a, { code, name }) => Object.assign(a, {
						[code]: name,
					}), {})
			
			if (editor?.tags) {
				Object.assign(editor.tags, {
					value:
						Object.keys(editor.tags.value)
							.map(tag => ({
								tag,
								code: tag,
							}))
				})
			} else {
				editor.tags = {}
			}
			
			state.editor = editor
			
			let counter = 0
			let message = 'Content text transformation'
			const total = state.currentContentTexts.length
			const client = store.getters['useClient/getClientIdentifier']
			const currentMedias = store.getters['useMedias/getMedias']

			for (const contentText of localObjects) {
				counter += 1
				emit({ message, counter, total })
				const { clientMedias, htmlTranslations, specificInfo } = contentText
				const { alternatives = [], clientGroups = [], guestExclusive, onlyFor = [], specificLanguages = [] } = specificInfo
				let newClientMedias = []
				for (const clientMedia of clientMedias) {
					const { id } = clientMedia
					const media = await medias.get({ client, id })
					Object.entries(media.medias)
						.forEach(([ language, value ]) => {
							const {blob, contentType} = value
							Object.assign(media.medias[language], {
								contentType,
								objectURL: URL.createObjectURL(blob),
							})
						})
					newClientMedias = [
						...newClientMedias,
						media,
					]
				}
				
				if (newClientMedias.length) {
					Object.assign(contentText, {
						clientMedias: {
							medias: newClientMedias,
							tab: 0,
						},
					})
				}
				
				Object.assign(contentText, {
					specificInfo: {
						clientGroups:
							(clientGroups ?? [])
								.map(({ id:code }) => ({
									name: clientGroupsCodes[code],
									code,
								})),
						guestExclusive,
						onlyFor:
							(onlyFor ?? [])
								.map(name => ({
									name,
									code: name,
								})),
						alternatives:
							(alternatives ?? [])
								.map(name => ({
									name,
									code: name,
								})),
						specificLanguages:
							(specificLanguages ?? [])
								.map(name => ({
									name,
									code: name,
								}))
					},
					tags:
						contentText.tags
							.map(tag => ({
								tag,
								code: tag,
							})),
				})

				for (const [ language, value ] of Object.entries(htmlTranslations)) {
					// const sections = value.matchAll(/(<a.+data-section.+">)/g)
					//
					// for (const section of sections) {
					// 	const html = section[1]
					// 	htmlTranslations[language] =
					// 		htmlTranslations[language]
					// 			.replace(html, `${section[1]} class="section-blot"`)
					// }

					const tags = value.matchAll(/\[\*.+\*\]/g)

					for (const tag of tags) {
						const html = tag[0]
						const patterns = {
							id: /id-([0-9a-zA-Z]+)\s/,
							width: /width-(\d+)(px|%)?/,
						}
						let id = html.match(patterns.id)
						
						id =
							id
								? id[1]
								: null
						
						if (!id) continue
						
						const found =
							currentMedias
								.find(({ id:ID }) => ID === id)
						const { slug } = found
						const { objectURL:src, contentType } =
							found
								.medias[language]
						
						const result = html.match(patterns.width)
						let styles
						let unit
						let width
						
						if (result) {
							width = result[1]
							unit = result[2] ?? 'px'
							styles = `width:${width}${unit}`
						}
						
						let img = `<img id="${self.crypto.randomUUID()}" src="${src}" data-content-type="${contentType}" data-media="${id}" data-media-slug="${slug}"`
						img +=
							styles
								? ` style="${styles}"`
								: ''
						img += `>`
						htmlTranslations[language] =
							htmlTranslations[language]
								.replace(html, img)
					}
				}
			}
			state.currentContentTexts = localObjects
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
