<template>
	<OrderList
		v-model='elements'
		data-key='id'
	>
		<template #item='{item}'>
			<div>
				<div class='image-container'>
					<Galleria
						:show-indicators='true'
						:show-thumbnails="false"
						:value='Object.values(item.medias)'
						containerStyle="max-width: 200px"
					>
						<template #item='{item}'>
							<div
								class='image-container audio'
								v-if='new withString(item.contentType).isTypeAUDIO().value'
							>
								<audio
									controls
								>
									<source
										:src='item.objectURL'
										:type='item.contentType'>
								</audio>
							</div>
							<div
								v-else
								class='image-container small'
							>
								<embed
									v-if='new withString(item.contentType).isTypePDF().value'
									:src='item.objectURL'
									type='application/pdf'
									:style="{width: '100%', height: '100px'}"
								/>
								<img
									v-if='new withString(item.contentType).isTypeIMAGE().value'
									:src='item.objectURL'
									class='image'
								/>
								<Lottie
									v-else-if='new withString(item.contentType).isTypeJSON().value'
									class='lottie cursor'
									ref='lottie'
									:src='item.objectURL'
									:slug='item.slug'
								/>
							</div>
						</template>
						<template #caption>
							<Tag>{{(item ?? {}).slug}}</Tag>
						</template>
						<template #indicator='{index}'>
							<Tag>{{Object.keys(item.medias)[index]}}</Tag>
						</template>
					</Galleria>
				</div>
			</div>
		</template>
	</OrderList>
</template>

<script>

import {computed} from 'vue'
import { withString } from '@affordancestudio/functions'
import Lottie from '@/components/cms/lottie/Lottie'

export default {
	name: 'Medias',
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: [ 'update:modelValue' ],
	components: {
		Lottie,
	},
	setup(props, { emit }) {
		const elements = computed({
			get:() => props.modelValue,
			set: value => emit('update:modelValue', value)
		})
		return {
			elements,
			withString,
		}
	}
}
</script>

<style scoped lang='scss'>
::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}
}
.image-container {
	&.audio {
		width: 100%;
	}

	&.tiny {
		width: 200px;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 180px;
			height: 50px;
		}

		> .lottie {
			width: 100px;
			height: 50px;
		}

		> .pdf {
			width: 100px;
			height: 100px;
		}
	}
	&.small {
		width: 300px;
		height: 200px;
		margin-right: 1rem;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 200px;
			max-height: 200px;
		}

		> .lottie {
			width: 200px;
			height: 200px;
		}

		> .pdf {
			width: 200px;
			height: 200px;
		}
	}

	&.big {
		width: 100%;
		height: 300px;

		>.image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #eee;
		}

		> .lottie {
			width: 100%;
			height: 100%;
		}

		> .pdf {
			width: 100%;
			height: 100%;
		}
	}

	&.zoomed {
		width: 100%;
		height: 100%;

		> .image {
			background-color: #eee;
			width: 100%;
			height: 100%;
		}

		> .lottie {
			height: 100%;
		}

		> .pdf {
			width: 400px;
			height: 600px;
		}
	}
}
</style>
