import { usePinia } from '@/stores/pinia/main'
import { entries } from '@/common/code'
import { storeToRefs } from 'pinia'
import { getRandomID } from '@affordancestudio/functions'

const clientMedias = () => ({
	medias: [],
	tab: 0,
})
const clientMediaIds = () => []
const contentTextIds = () => []
const documentTranslations = client => {
	const { setting } = client
	const { acceptedLanguages } = setting
	return acceptedLanguages
		.reduce((a, language) =>
			Object.assign(a, {
				[language]: {},
			}), {})
}
const duration = () => 0
const htmlTranslations = client => {
	const { setting } = client
	const { acceptedLanguages } = setting
	return acceptedLanguages
		.reduce((a, language) =>
			Object.assign(a, {
				[language]: '',
			}), {})
}
const id = () => getRandomID(10)
const schemaId = () => null
const slug = slug => slug ?? '0000000000'
const specificInfo = () => ({
	specificInfo: {
		exclusive: false,
		groupExclusive: false,
		onlyFor: false,
		roleExclusive: false,
		guestExclusive: false,
	}
})
const tags = () => []

const elements = {
	contentTextIndex: {
		clientMedias: () => clientMedias(),
		elementType: () => 'Content::Text',
		htmlTranslations: ({ get }) => htmlTranslations(get.value('client')),
		id: () => id(),
		slug: ({ slug:s }) => slug(s),
		specificInfo: () => specificInfo(),
		tags: () => tags(),
		updatedAt: () => Date().toString(),
	},
	clientEventIndex: {
		clientMediaIds: () => clientMediaIds(),
		documentTranslations: ({ get }) => documentTranslations(get.value('client')),
		duration: () => duration(),
		elementType: () => 'Client::Event',
		id: () => id(),
		schemaId: () => schemaId(),
		slug: ({ slug:s }) => slug(s),
		tags: () => tags(),
	},
	clientTimelineIndex: {
		clientMediaIds: () => clientMediaIds(),
		contentTextIds: () => contentTextIds(),
		documentTranslations: ({ get }) => documentTranslations(get.value('client')),
		elementType: () => 'Client::Timeline',
		id: () => id(),
		schemaId: () => schemaId(),
		slug: ({ slug:s }) => slug(s),
		tags: () => tags(),
		timelineEvents: () => ({
			data: [],
			elementType: null,
			meta: {},
		}),
	}
}

export const getNewElement = (name, slug) => {
	const pinia = usePinia()
	const { get } = storeToRefs(pinia)
	const box = { get, slug }
	const fields = elements[name]
	return entries(fields)
		.reduce((a, [ field, func ]) => {
			return Object.assign(a, {
				[field]: func(box)
			})
		}, {})
}
