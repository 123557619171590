import { defineStore } from 'pinia'
import { getNewElement } from '@/common/structures'
import { transformDataField } from '@/common/walkThrough/actions'
import { hasFields } from '@/common/walkThrough/conditions'
import { walkThrough } from '@affordancestudio/functions'
import { entries } from '@/common/code'
import { uniq } from 'lodash'
import { engage } from '@affordancestudio/engage-cms'

export const usePinia = defineStore('main', {
	state: () => ({
		params: {},
		_: {
			data: {},
			meta: {},
		},
		refreshes: [],
	}),
	getters: {
		get: state => name => state._?.data?.[name] ?? {},
		getData: state => name => state._?.data?.[name]?.data ?? [],
		getElementType: state => name => state._?.data?.[name]?.elementType ?? null,
		getEditor: state => name => state._?.meta[name]?.editor ?? {},
		getMeta: state => name => state._?.meta?.[name] ?? {},
		getParams: state => property => state.params[property],
	},
	actions: {
		setSameParam(property) {
			const param = this.params?.[property]
			if (!param) return
			const { current } = param
			Object.assign(this.params[property], {
				old: current
			})
		},
		setParams(object) {
			const { params = {} } = this
			entries(object)
				.forEach(([ property, current ]) => {
					const v = params[property] ?? {}
					Object.assign(params, {
						[property]:
							Object.assign(v, {
								old: v?.old ?? null,
								current,
							})
					})
				})
		},
		addElement(name, slug) {
			const newElement = getNewElement(name, slug)
			this._.data[name].data = [
				newElement,
				...this._.data[name].data
			]
		},
		removeElement(name, id) {
			this._.data[name].data = [
				...this._.data[name].data
					.filter(({ id:ID }) => id !== ID)
			]
		},
		set(name, value) {
			const action = transformDataField
			const condition = hasFields(['meta.editor'])
			walkThrough({ action, condition, value })
			const { meta } = value
			this._.data[name] = value
			if (meta) this._.meta[name] = meta
			
			// const { meta, localObjects } = value
			// console.log(name,meta)
			// const action = transformDataField
			// const condition = hasFields(['meta.editor'])
			// walkThrough({ action, condition, value })
			// this._.data[name] = localObjects ?? value
			// if (meta) this._.meta[name] = meta
		},
		addRefresh(value) {
			this.refreshes = uniq([
				...this.refreshes,
				value,
			])
		},
		refresh({ client }) {
			const { refreshes } = this
			refreshes
				.forEach(async refresh => {
					const query = `client${refresh}Index`
					const variables = {
						limit: 500
					}
					;(await engage.boxGet(query, { client, variables }))
						.fold(()=>{}, payload => this.set(query, payload))
				})
			this.refreshes = []
		},
	}
})
