<template>
	<div class='p-col-3'>
		<Panel
			toggleable
			collapsed
		>
			<template #header>
				<h4>Medias<Badge :value='refElements.length' severity="info" class="p-ml-2" /></h4>
			</template>
			<template #icons>
				<Button
					icon='pi pi-pencil'
					class='p-button-success p-mr-2'
					v-tooltip.bottom='`Modify Medias`'
					@click.stop='showElements(node)'
				/>
			</template>
			<Medias
				v-model="refElements"
			/>
		</Panel>
	</div>
</template>

<script>
import Medias from '@/components/cms/innerComponents/Medias'
import {computed} from 'vue'
import {useStore} from 'vuex'
import EventBus from '@/JS/event-bus'

export default {
	name: 'clientMediaIds',
	props: {
		modelValue: {
			type: Array,
			default: () => [],
		},
		node: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		Medias,
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const store = useStore()

		const elementList = computed(() => store.getters[`useMedias/getMedias`])
		const refElements = computed({
			get: () =>
				elementList.value
					.filter(({ id }) => props.modelValue.includes(id)),
			set: value => {
				emit('update:modelValue', value)
			},
		})

		const showElements = x => {
			EventBus.emit('showMedias', x)
		}

		return {
			refElements,
			showElements,
		}
	}
}
</script>

<style scoped>

</style>
