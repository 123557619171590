import en from './languages/en.json'
import fr from './languages/fr.json'

const messages = {
	en,
	fr,
}

export {
	messages
}
