<template>
	<div class='p-col-6'>
		<Panel
			toggleable
			collapsed
		>
			<template #header>
				<h4>Fields</h4>
			</template>
			<Toolbar>
				<template #start>
					<Tag class="tag-field p-mr-2">Schema</Tag>
				</template>
				<template #end>
					<Dropdown
						:filter="true"
						:options='schemas'
						class="tag-green"
						optionLabel='name'
						optionValue='code'
						placeholder='Select a schema'
						v-model='refSchemaId'
						@change="onSchemaId"
					/>
				</template>
			</Toolbar>
			<TabView
				v-if="isDocumentTranslations(refDocument)"
			>
				<TabPanel
					v-for='language of acceptedLanguages'
					:key='`category-${language}`'
				>
					<template #header>
						<tag>{{language.toUpperCase()}}</tag>
					</template>
					<template #default>
						<Fields
							v-model="refDocument[language]"
						/>
					</template>
				</TabPanel>
			</TabView>
		</Panel>
	</div>
</template>

<script>
import Fields from '@/components/cms/innerComponents/Fields'
import { computed, ref, watchEffect } from 'vue'
import {documentTransform, getRenamedProperties, isDocumentTranslations} from '@/common/code'
import { usePinia } from '@/stores/pinia/main'

export default {
	name: 'documentTranslations',
	props: {
		meta: {
			type: Object,
			default: () => ({}),
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		schemaId: {
			type: String,
			default: () => '',
		},
	},
	components: {
		Fields,
	},
	emits:[ 'update:modelValue', 'changeSchemaId' ],
	setup(props, { emit }) {
		const pinia = usePinia()
		const { get } = pinia
		const { acceptedLanguages } = get('client')?.setting

		//	*******************************************************************************************
		//	REF
		//	*******************************************************************************************

		const refDocument = ref({})
		const refMeta = ref({})
		const refSchemaId = ref('')

		//	*******************************************************************************************
		//	COMPUTED
		//	*******************************************************************************************

		const schemas = computed(() =>
			refMeta?.value?.editor?.schemas
				? getRenamedProperties(refMeta.value.editor.schemas, { id: 'code', slug: 'name' })
				: []
		)

		//	*******************************************************************************************
		//	WATCH
		//	*******************************************************************************************

		watchEffect(() => refDocument.value = props.modelValue)
		watchEffect(() => refMeta.value = props.meta)
		watchEffect(() => refSchemaId.value = props.schemaId)

		//	*******************************************************************************************
		//	FUNCTIONS
		//	*******************************************************************************************

		const onSchemaId = ({ value:schemaId }) => {
			const documentTranslations = refDocument.value
			const schema =
				refMeta.value.editor.schemas
					.filter(({ id }) => id === schemaId)
					.pop()
			refDocument.value = documentTransform({ acceptedLanguages, documentTranslations, schema })
			emit('changeSchemaId', schemaId)
		}

		//	*******************************************************************************************
		//	RETURN
		//	*******************************************************************************************

		return {
			acceptedLanguages,
			isDocumentTranslations,
			onSchemaId,
			refDocument,
			refSchemaId,
			schemas,
		}
	}
}
</script>

<style lang='scss' scoped>
.p-multiselect {
	width: 100%;
}

::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);

		img.flag {
			width: 17px;
		}
	}
}

@media screen and (max-width: 640px) {
	.p-multiselect {
		width: 100%;
	}
}

.accordion-title {
	margin-left: 0.75rem;
}

.image-container {
	&.audio {
		width: 100%;
	}

	&.tiny {
		width: 200px;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 180px;
			height: 50px;
		}

		> .lottie {
			width: 100px;
			height: 50px;
		}

		> .pdf {
			width: 100px;
			height: 100px;
		}
	}
	&.small {
		width: 300px;
		height: 200px;
		margin-right: 1rem;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 200px;
			max-height: 200px;
		}

		> .lottie {
			width: 200px;
			height: 200px;
		}

		> .pdf {
			width: 200px;
			height: 200px;
		}
	}

	&.big {
		width: 100%;
		height: 300px;

		>.image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #eee;
		}

		> .lottie {
			width: 100%;
			height: 100%;
		}

		> .pdf {
			width: 100%;
			height: 100%;
		}
	}

	&.zoomed {
		width: 100%;
		height: 100%;

		> .image {
			background-color: #eee;
			width: 100%;
			height: 100%;
		}

		> .lottie {
			height: 100%;
		}

		> .pdf {
			width: 400px;
			height: 600px;
		}
	}
}

.cursor {
	cursor: progress;
}

.card {
	background: #ffffff;
	padding: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
	margin-bottom: 2rem;
}

.p-dropdown {
	width: 14rem;
	font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid #dee2e6;

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}

.margin-left {
	margin-left: 1rem;
}

.product-item {
	display: flex;
	align-items: center;
	padding: .5rem;
	width: 100%;

	img {
		width: 75px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 1rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.product-category-icon {
		vertical-align: middle;
		margin-right: .5rem;
		font-size: .875rem;
	}

	.product-category {
		vertical-align: middle;
		line-height: 1;
		font-size: .875rem;
	}
}

@media screen and (max-width: 576px) {
	.product-item {
		flex-wrap: wrap;

		.image-container {
			width: 100%;
			text-align: center;
		}

		img {
			margin: 0 0 1rem 0;
			width: 100px;
		}
	}
}

.no-box-shadow {
	box-shadow: none;
}

.padding-4 {
	padding: 4px;
}

.width-25pc {
	width: 25%;
}

::v-deep(.edited) {
	background-color: rgba(0, 128, 0, 0.4) !important;
}

::v-deep(.cannotSave) {
	background-color: rgba(255, 0, 0, 0.25) !important;
}

.cannot-save-text {
	color: red;
}

.cannot-save-background {
	background-color: rgba(255, 0, 0, 0.25);
}

@-webkit-keyframes flash-background {
	0% {
		background-color: rgba(0, 255, 0, 0.5);
		opacity: 1;
	}
	100% {
		background-color: inherit !important;
	}
}

::v-deep(.flash-background) {
	-webkit-animation-name: flash-background;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease;
	-moz-animation-name: flash-background;
	-moz-animation-duration: 1000ms;
	-moz-animation-iteration-count: 1;
	-moz-animation-timing-function: ease;
}
.state-badge {
	border-radius: 2px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;
	&.state-development {
		background-color: #ffcdd2;
		color: #c63737;
	}
	&.state-test {
		background-color: #ffd8b2;
		color: #805b36;
	}
	&.state-production {
		background-color: #c8e6c9;
		color: #256029;
	}

}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

.true-icon {
	color: #256029;
	font-weight: bold;
}

.false-icon {
	color: #C63737;
	font-weight: bold;
}
.tag-black {
	background-color: #333;
}
</style>
