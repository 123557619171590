<template>
  <div
    :ref='`lottieAnimation-${slug}`'
    class="lottie-class"
    @mouseenter="lottieAnimation.goToAndPlay(0)"
  />
</template>

<script>
import lottie from 'lottie-web'

export default {
	name: 'Lottie',
	props: [ 'src', 'slug' ],
	data() {
		return {
			lottieAnimation: null,
			play: false
		}
	},
	watch: {
		play(play) {
			if (play) this.lottieAnimation.play()
			else this.lottieAnimation.pause()
		},
		src(newValue) {
			if (newValue) this.$nextTick(() => { this.loadLottie() })
		}
	},
	methods: {
		destroyAnim() {
			if (this.lottieAnimation) {
				this.lottieAnimation.destroy()
				this.lottieAnimation = null
			}
		},
		loadLottie() {
			if (this.lottieAnimation) {
				this.destroyAnim()
			}
			const { slug, src:path } = this
			if (!path) return
			const container = this.$refs[`lottieAnimation-${slug}`]
			this.lottieAnimation = lottie.loadAnimation({
				container,
				path,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				rendererSettings: {
					preserveAspectRatio: 'xMinYMin meet',
				},
			})
		}
	},
	mounted() {
		this.loadLottie()
	},
	beforeUnmount() {
		this.destroyAnim()
	}
}
</script >
