import { createStore } from 'vuex'
import { isEqual, takeRight } from 'lodash'
import { useCMS } from './cms'
import { useCards } from './cards'
import { useCategories } from './categories'
import { useCheckpoints } from './checkpoints'
import { useClient } from './client'
import { useContentTexts } from './contentText'
// import { useEvents } from './events'
import { useMedias } from './medias'
import { useMiscellaneous } from './miscellaneous'
import { useSchemas } from './schemas'
import { useSections } from './sections'
// import { useTimelinesEvents } from './timelines'
import { useTutorials } from './tutorials'

const undo = store => {
	store.subscribe(({ type, payload }, state) => {
		if (!type || !payload) return
		const { route, path, field, parent, _extra } = payload
		const elements = type.split('/')
		const storeName = elements.shift()
		const commit = elements.pop()
		if (!route) return
		const mutation = state.undoMutation
		if (!mutation) return
		const {
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newValue,
			previousEdited,
			previousParentEdited,
			previousParentTab,
			previousValue,
		} = mutation

		if (isEqual(previousValue, newValue)) return

		const undo = {
			commit,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newValue,
			parent,
			path,
			previousEdited,
			previousParentEdited,
			previousParentTab,
			previousValue,
			route,
			storeName,
		}
		if (_extra) Object.assign(undo, { _extra })
		state.undo = [
			...takeRight(state.undo, state.undoIndex),
			undo,
		]
		state.undoIndex = Math.min(state.undoMax, state.undoIndex + 1)
		state.undoMutation = null
	})
}

export const store = createStore({
	devtools: true,
	plugins: [undo],
	modules: {
		useCMS,
		useCards,
		useCategories,
		useCheckpoints,
		useClient,
		useContentTexts,
		// useEvents,
		useMedias,
		useMiscellaneous,
		useSchemas,
		useSections,
		// useTimelinesEvents,
		useTutorials,
	},
	state: {
		undo: [],
		undoMax: 100,
		undoIndex: 0,
		undoMutation: null,
	},
	getters: {
		getUndo: state => {
			if (!state.undo.length) return null
			return state.undo[state.undoIndex - 1]
		},
		getRedo: state => {
			if (!state.undo.length) return null
			return state.undo[state.undoIndex]
		},
		isRedoAvailable: state => state.undoIndex < state.undo.length ? true : false,
		isUndoAvailable: state => state.undo.length && state.undoIndex ? true : false,
		isUndo: state => ({ route }) => {
			return !!state.undo
				.slice(0, state.undoIndex)
				.find(({ route:_route }) => route === _route)
		}
	},
	mutations: {
		clearUndo(state) {
			Object.assign(state, {
				undo: [],
				undoIndex: 0,
				undoMutation: null,
			})
		},
		undoIndex(state, { increment }) {
			if (state.undoIndex + increment < 0) return
			state.undoIndex += increment
		},
		removeUndo(state, { route:_route }) {
			state.undo =
				state.undo
					.filter(({ route }) => route !== _route)
		}
	},
	actions: {}
})
