import { has } from 'lodash'
import {isStructure} from '@/components/cms/structures/structures'

export const hasFields = paths => ({ value }) => {
	return paths
		.reduce((a, path) =>
			a || has(value, path)
		, false)
}

export const canSaveElement = ({ value }) => {
	const { elementType } = value
	return elementType
		? isStructure(elementType.split('::').join(''))
		: false
}
