import {notEmpty} from '@/common/validators'

export default {
	_actions: {
		end: x => ({
			input: {
				...x,
			}
		})
	},
	timelineId: {
		default: '',
		validator: {
			test: notEmpty,
			message: 'TimelineId is invalid',
		},
	},
	interval: {
		default: 0,
	},
	delta: {
		default: 0,
	},
	originId: {
		default: '',
	},
	eventId: {
		default: '',
		path: 'event.id',
		validator: {
			test: notEmpty,
			message: 'EventId is invalid',
		},
	},
}
