import { computed, ref, resolveDynamicComponent } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { isPlainObject } from 'is-plain-object'
import {cloneDeep, get, isEmpty, set, uniqBy} from 'lodash'
import {getValueByProperty, compose, walkThrough, timeUnitsFromSeconds, slugify} from '@affordancestudio/functions'

/**
 * @module common
 */

/**
 * Function that do nothing
 */
const emptyFunction = () => {}

/**
 * Returns right class for edited, cannotSave elements
 * @param _cannotSave
 * @param _edited
 * @returns {string|null}
 */
export const rowClass = ({ _cannotSave, _edited }) =>
	_cannotSave
		? 'cannotSave'
		: _edited
			? 'edited'
			: null

/**
 * Primevue's live project components Global filters
 * @type {Ref<UnwrapRef<{name: {value: null, matchMode: string}, global: {value: null, matchMode: string}}>>}
 */
export const globalFilters = ref({
	'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
})

/**
 * Change Date format to fr-CA
 * @param date
 * @returns {string}
 */
export const formatDate =
		date =>
			new Date(date)
				.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })

/**
 * Clears element's save status
 * @param object
 */
export const clearSaveInfo = object => {
	delete object._cannotSave
	Object.keys(object)
		.forEach(x => {
			const o = object[x]
			if (isPlainObject(o)) {
				delete o._cannotSave
				clearSaveInfo(o)
			}
		})
}

export const toastError = ({ toast }) => ({ code, message, messages }) => {
	const work = message
		? [ message ]
		: messages
	work
		.forEach(detail => {
			toast.add({
				severity: 'error',
				summary: `Error code ${code}`,
				detail,
				life: 4000,
			})
		})
}

export const highlightErrors = (validateSchema, object, savingErrors) => {
	const { errors } = validateSchema
	if (errors.length) savingErrors.value += errors.length

	errors
		.forEach(error => {
			let { instancePath, params: { missingProperty } = {} } = error

			if (!instancePath.length && missingProperty) {
				instancePath = `/${missingProperty}`
			}

			let arrayPath = instancePath.split('/').slice(1)

			do {
				const property = arrayPath.pop()
				const path = [ ...arrayPath, '_cannotSave' ].join('.')
				const _cannotSave = Object.assign({},get(object, path) ?? {}, { [property]: false })
				set(object, path, _cannotSave)
			} while (arrayPath.length)
		})
}

export const displaySavingErrors = () => {
	// const nb = savingErrors.value
	// const plural = nb > 1
	// console.log(savingErrors)
	// console.log(toast)
	// toast.add({
	// 	severity: 'error',
	// 	summary: 'Saving',
	// 	detail: `There ${plural ? 'are' : 'is' } ${nb} error${plural ? 's' : ''} preventing saving some objects.`,
	// 	life: 4000,
	// })
}

export const getSchema = fields => {
	const NEEDED = 'needed'
	const VALUE = 'value'
	let properties = {}
	let required = []
	const schema = Object.entries(fields)
		.reduce((a, [ property, data ]) => {
			const clone = cloneDeep(data)
			if (isPlainObject(clone)) {
				if (!Object.prototype.hasOwnProperty.call(clone, NEEDED)) {
					Object.assign(properties, {
						[property]: getSchema(clone),
					})
				} else if (clone?.needed) {
					Object.assign(properties, {
						[property]: {
							properties: {
								value: {
									type: (({ type }) => {
										return {
											Editor: 'string',
											number: 'integer',
										}[type] ?? data?.type.toLowerCase() ?? 'string'
									})(data),
									minLength: 1,
								}
							},
							required: [ VALUE ]
						},
					})
				}
			}
			if (!isEmpty(properties)) {
				Object.assign(a, {
					properties,
				})
			}
			if (required.length) {
				Object.assign(a, {
					required,
				})
			}
			return a
		}, {})
	return cloneDeep(schema)
}

export const addTag = ({
	event,
	field = null,
	namespace,
	isNewTags = false,
	route,
	store,
}) => {
	const path = {
		true: 'newTags',
		false: 'tags'
	}[isNewTags]
	const tags = {
		newTags: store.getters[`${namespace}/getNewTags`],
		tags: field?.tags ?? [],
	}[path]
	const { value:tag } = event.target
	event.target.value = null
	const value = uniqBy([
		...tags,
		{
			tag,
			code: tag,
		}
	], 'tag')
		.sort((a, b) => a.tag.localeCompare(b.tag))
	store.dispatch(`${namespace}/addUndo`, { route, field, path, value })
}

export const specificsSchema = ref([
	{
		label: 'Exclusives', code: 'exclusives', path: '', type: 'object', value: true,
		items: [
			{ label: 'Group', value: 'groupExclusive' },
			{ label: 'Guest', value: 'guestExclusive' },
			{ label: 'Local', value: 'localExclusive' },
			{ label: 'Role', value: 'roleExclusive' },
		],
	},
	{
		label: 'Only For', code:'onlyFor', path: 'onlyFor', type: 'array', value: null,
		items: [
			{ label: 'Admin', value: 'admin' },
			{ label: 'Group Admin', value: 'group_admin' },
			{ label: 'Group Owner', value: 'group_owner' },
			{ label: 'Invitor', value: 'invitor' },
			{ label: 'Reviewer', value: 'reviewer' },
			{ label: 'User', value: 'user' },
		],
	},
])

export const specificsSchemaForSave = computed(() =>
	specificsSchema.value
		.reduce((a, { items, path, type }) =>
			[
				...a,
				...items
					.map(({ value }) => ({
						path,
						type,
						value,
					}))
			], [])
)

export const specificsSchemaFlat = computed(() =>
	specificsSchema.value
		.reduce((a, { items }) => [ ...a, ...items ], [])
)

export const getSpecificValue = _value =>
	specificsSchemaFlat.value
		.filter(({ value }) => value === _value)

export const getSpecificKeyValue = (_key, _value) =>
	specificsSchemaFlat.value
		.filter(({ value }) => _value && _key === value)

export const setSpecifics = ({ field, namespace, route, store, value:_value }) => {
	const value =
		_value
			.reduce((a, { value:v1 }) => {
				const { path, type, value } =
					specificsSchemaForSave.value
						.find(({ value:v2 }) => v1 === v2 )
				switch(type) {
				case 'object':
					return Object.assign(
						a,
						path
							? { [path]: { [value]: true } }
							: { [value]: true }
					)
				case 'array':
					return Object.assign(a, {
						[path]: [
							...(a[path] ?? []),
							value
						]
					})
				}
			}, {})
	store.dispatch(`${namespace}/addUndo`, { route, field, path: 'specificInfo', value })
}

export const setTags = ({ field, namespace, route, store, value }) => {
	store.dispatch(`${namespace}/addUndo`, { route, field, path: 'tags', value })
}

export const onPage = ({ store }) => {
	store.commit('clearUndo')
}

export const confirmDialogModal = ({
	accept = emptyFunction,
	acceptClass = 'p-button-danger',
	confirm,
	group = 'modal',
	header = 'No header specified.',
	icon = 'pi pi-info-circle',
	message = 'No message specified.',
	reject = emptyFunction,
}) => {
	confirm.require({ message, header, icon, acceptClass, accept, reject, group })
}

export const getRenamedProperties = (array, properties) => {
	const keys = Object.keys(properties)
	return array
		.map(x =>
			Object.entries(x)
				.reduce((a, [ key, value ]) =>
					keys.includes(key)
						? Object.assign(a, {
							[properties[key]]: value,
						})
						: a, {}))
}
const getNameCode = ([ name, code ]) => ({ name, code })
const getName = name => ({ name, code: name })

export const sortByName = x => x.sort((a, b) => a.name.localeCompare(b.name))
export const entries = x => Object.entries(x)
export const values = x => Object.values(x)
export const keys = x => Object.keys(x)
export const mapNameCode = x => x.map(getNameCode)
export const mapName = x => x.map(getName)

export const saveSpecifics =
	({ boxPost, client, data, mutation }) =>
		async ({ elementType, id:elementId }) => {
			const { specificInfo } = data
			const { alternatives, clientGroups, guestExclusive, onlyFor, specificLanguages } = specificInfo
			const getCode = getValueByProperty('code')
			const input = {
				clientGroupIds: clientGroups.map(getCode),
				elementId,
				elementType,
				guestExclusive,
				onlyFor: (onlyFor ?? []).map(getCode),
				alternatives: (alternatives ?? []).map(getCode),
				specificLanguages: specificLanguages.map(getCode),
			}
			const variables = { input }
			return (await boxPost(mutation, { client, variables }))
				.fold(()=>{}, x => x)
		}
	
export const getBadgeData = data =>
	({
		'Array': value => value.length,
		'Boolean': data => String(data),
		'String': () => 1,
		'Object': () => 1,
	})[data.constructor.name](data)

export const codeTags = tags => tags.map(setTag)
export const setTag = tag => ({
	code: tag,
	tag,
})
export const setElementTags = element => {
	const { tags = [] } = element
	return tags.length
		? Object.assign(element, {
			tags:
				tags
					.map(tag => ({
						tag,
						code: tag,
					}))
		})
		: element
}
export const getTag = ({ tag }) => tag
export const getTags = tags =>
	(tags ?? [])
		.map(getTag)

export const documentTransform = ({ acceptedLanguages, documentTranslations, schema }) => {
	const { subjectsToH = {} } = schema ?? {}
	
	acceptedLanguages
		.forEach(language => {
			Object.assign(documentTranslations, {
				[language]: {
					...Object.entries(cloneDeep(subjectsToH))
						.reduce((a, c) => {
							const [ field, data ] = c
							const { type } = data
							const defaultValue = {
								Integer: 0,
								String: '',
							}
							const value =
								documentTranslations?.[language]?.[field]?.value
								?? documentTranslations?.[language]?.[field]
								?? defaultValue[type]
								?? ''
							return Object.assign(a, {
								[field]: Object.assign(data, {
									value,
								})
							})
						}, {})
				}
			})
		})
	return documentTranslations
}

const getEditorText = fields => {
	return Object.entries(fields)
		.reduce((a, [ language, { editorText } ]) => {
			return Object.assign(a, {
				[language]: editorText,
			})
		}, {})
}

const convertImageToCustom = data => {
	const patternImage = /<img [^>]*src="[^"]*"[^>]*>/
	const patternData = /(?=style="\D*(?<width>\d+\.*\d*)(?<units>px|%?)).+(?=data-media="(?<id>[^"]+))/
	
	return Object.entries(data)
		.reduce((a, [ language, value ]) => {
			(value.match(patternImage) ?? [])
				.forEach(match => {
					const matches = match.match(patternData)
					const { groups, input } = matches
					const { id, units, width:WIDTH } = groups
					const width = Math.round(WIDTH)
					let img = `[* img`
					img += id ? ` id-${id}` : ``
					img += width ? ` width-${width}` : ``
					img += units ? `${units}` : ``
					img += ` *]`
					value = value
						.replace(input, img)
				})
			return Object.assign(a, {
				[language]: value,
			})
		}, {})
}

export const getFieldValue = fields =>
	Object.entries(fields)
		.reduce((a, [ language, data ]) =>
			Object.assign(a, {
				[language]: Object.entries(data)
					.reduce((a, [fieldName, { type, value }]) =>
						Object.assign(a, {
							[fieldName]:
								value === null
									? type === 'Boolean'
										? false
										: ''
									: value
						}), {})
			}), {})

const setParents =
	family =>
		family
			.reduce((a, c) => {
				const { parentId } = c
				if (a[parentId]) Object.assign(a[parentId], [ ...a[parentId], c ])
				else Object.assign(a, { [parentId]: [ c ] })
				return a
			}, {})

const recursive =
	(parents, id) =>
		parents[id]
			? { children: parents[id]
				.map(x => Object.assign(x, { ...recursive(parents, x.id) })) }
			: {}

const getNull =
	o =>
		Object.entries(o)
			.filter(([ id ]) => id === 'null')
			.pop()
			.pop()

const rootSection =
	parents => ({
		root:
			getNull(parents)
				.pop(),
		parents,
	})

const rootCheckpoints =
	parents => ({
		root:
			getNull(parents)
				.reduce((a, c) =>
					Object.assign(a, {
						[c.id]: c,
					}), {}),
		parents,
	})

const responseSection =
	({ root, parents }) => ({
		response: Object.assign(root, {
			...recursive(parents, root.id)
		})
	})

const responseCheckpoints =
	({ root, parents }) => ({
		response: [
			...Object.entries(root)
				.reduce((a, [id, value]) => [
					...a,
					Object.assign(value, {
						...recursive(parents, id)
					})
				], [])
		]
	})

export const unflattenCheckpoints = compose(responseCheckpoints, rootCheckpoints, setParents)
export const unflattenSectionTree = compose(responseSection, rootSection, setParents)

export const confirmDeleteTreeElement = ({
	children = [],
	client,
	confirm,
	data,
	funcStoreDelete = x => x,
	funcEnd = x => x,
	funcRefresh = x => x,
	group,
	mutation,
	parents,
	taskPost,
	toast,
}) => {
	const { id, elementType = '', oldParentId, slug } = data
	const parentSlug =
		oldParentId
			? parents.value.find(({ code }) => code === oldParentId).name
			: null
	let header =
		children.length
			? parentSlug
				? `All children will have [${parentSlug}] for parent.`
				: 'All children will be orphans!'
			: 'Permanent Deletion of Element!'
	confirmDialogModal({
		group,
		confirm,
		message: `Delete ${elementType.toUpperCase()} [ ${slug} ]?`,
		header,
		accept: () => {
			const variables = { id }
			taskPost(mutation, { client, variables })
				.map(funcStoreDelete)
				.map(funcRefresh)
				.fork(toastError({ toast }), funcEnd)
		},
	})
}

export const mapId = ({ id }) => id
export const mapChildrenIds =
	children =>
		children
			.map(({ data }) => {
				const { id } = data
				return id
			})
export const mapSubjectToH = array =>
	array
		.reduce((a, { data }) => {
			const { id, slug } = data
			return Object.assign(a, {
				[slug]: id,
			})
		}, {})
export const getIds = x => x.map(mapId)
export const stringifyDocumentTranslations = compose(JSON.stringify, getFieldValue)
export const stringifyHtmlTranslations = compose(JSON.stringify, convertImageToCustom, getEditorText)

export const destructure = ({ data:DATA, structure }, { error = () => {} }) => {
	const _structure = cloneDeep(structure)
	const { _actions:actions = {} } = _structure
	const { start, end } = actions
	
	const data =
		start
			? start(DATA)
			: DATA
	
	delete _structure._actions
	
	let object =
		Object.entries(_structure)
			.reduce((a, [ property, { default:_default, path, rename, action = x => x, validator } ]) => {
				if (a === false) return false
				
				const value = get(data, path ?? property) ?? _default
				
				if (validator) {
					const { message, test } = validator
					if (!test(value)) {
						error(message)
						return false
					}
				}
				return Object.assign(a, {
					[rename ?? property]: action(value)
				})
			}, {})
	
	if (end) object = end(object)
	
	return object
}

export const purifyChildren = (parents, { data, children }) => {
	const { ancestorIds, id } = data
	return parents
		.filter(({ code }) => code !== id)
		.filter(({ code }) => !children.map(({ key }) => key).includes(code))
		.filter(({ code }) => !children.length ? code !== '' : true)
		.filter(({ code }) => !ancestorIds.includes(code))
}

export const purifyParents = (parents, { data, children }) => {
	const condition = ({ value }) =>
		value?.children && value.children.length
	
	const action = ({ memory = [], value = {} }) => {
		const { key } = value
		return [
			...memory,
			key,
		]
	}
	const memory =
		children.length
			? children.map(({ key }) => key)
			: []
	const value = children
	const allChildrenKeys = walkThrough({ action, condition, memory, value })
	const { id, parentId } = data
	return parents
		.filter(({ code }) => code !== id)
		.filter(({ code }) => !parentId ? code !== '' : true)
		.filter(({ code }) => !allChildrenKeys.includes(code))
}

export const transformParents =
	nodes =>
		[
			{
				name: '[ NONE ]',
				code: '',
			},
			...nodes
				.filter(({ id }) => isNaN(Number(id)))
				.map(({ id:code, slug:name }) => ({
					code,
					name,
				}))
				.sort((a, b) => a.name.localeCompare(b.name))
		]

export const changeProperty = async ({ node, property, event, save }) => {
	const { data } = node
	data[property] = event
	await save(node)
}

export const isDocumentTranslations = data =>
	isEmpty(data)
		? false
		: values(data)
			.reduce((a, c) =>
				isEmpty(c)
					? false
					: a, true)

export const incrementedSlug = s => {
	const pattern = /([^0-9.]*)(\d+$)/
	const match = slugify(s).match(pattern)
	if (!match) return `${s}-1`
	const slug =
		match[1]
			.split('-')
			.filter(x => x)
			.join('-')
	const number = Number(match[2]) + 1
	return `${slug}-${number}`
}

export const elementDeleted = ({ toast }) => () => {
	toast.add({
		detail: `Element deleted.`,
		life: 3000,
		severity: 'success',
		summary: 'Deleting element',
	})
}

export const displayTimeUnits = value =>
	entries(timeUnitsFromSeconds(value))
		.reduce((a, [ unit, value ]) =>
			`${a}${ a ? ' ' : ''} ${value}${unit}`
		, '')

export const isComponent = name => typeof resolveDynamicComponent(name) !== 'string'
