import { mutate } from '@/stores/functions'
import { getCheckpointSpecificInfo } from '@/common/editor.js'
import { reactive } from 'vue'
import { getRandomID } from '@affordancestudio/functions'
import { getIds } from '@/common/code'

const reset = () => ({
	editor: {},
	checkpoints: [],
})

export const useCheckpoints = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getCheckpoints: state => state.checkpoints,
		getParents: state => state.parents,
		getEditor: state => state.editor,
		getSchemaById: state => id => (state?.editor?.schemas ?? []).find(schema => schema.id === id),
	},
	mutations: {
		addCheckpoint(state, acceptedLanguages) {
			const newCheckpoint = reactive({
				children: [],
				clientMediaIds: [],
				documentTranslations:
					acceptedLanguages
						.reduce((a, language) =>
							Object.assign(a, {
								[language]: {},
							}), {}),
				id: getRandomID(10),
				parentId: null,
				schemaId: null,
				slug: '0000000000',
				specificInfo: {
					clientGroups: [],
					guestExclusive: false,
					onlyFor: [],
					alternatives: [],
					specificLanguages: [],
				},
				successCount: 1,
				tags: [],
			})
			state.checkpoints = [ newCheckpoint, ...state.checkpoints ]
		},
		deleteCurrentItem(state, { id }) {
			state.checkpoints =
				state.checkpoints
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		async setCurrentCheckpoints(state, { payload }) {
			const { data:checkpoints, meta } = payload
			const { editor } = meta
			
			if (editor.specificInfo) {
				Object.assign(editor.specificInfo, {
					...getCheckpointSpecificInfo(editor.specificInfo)
				})
			}
			
			state.editor = editor
			state.checkpoints = checkpoints
				.map(x => {
					const { ancestors } = x
					return Object.assign(x, {
						ancestorIds: getIds(ancestors),
					})
				})
				.sort((a, b) => a.slug.localeCompare(b.slug))
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
