import { documentTransform } from '@/common/code'
import { compose } from '@affordancestudio/functions'
const {
	keys,
	// setElementTags,
	setTag,
	values,
} = require('@/common/code')
import { usePinia } from '@/stores/pinia/main'
import {isEmpty} from 'lodash'

const fieldFunctions = {
	editor: {
		tags(value) {
			const v = value?.meta?.editor?.tags?.value
			if (!v) return value
			if (isEmpty(v)) {
				Object.assign(value.meta.editor.tags, {
					value: []
				})
			} else {
				Object.assign(value.meta.editor.tags, {
					value:
						keys(value.meta.editor.tags.value)
							.map(setTag)
				})
			}
			return value
		}
	},
	element: {
		documentTranslations(value) {
			const pinia = usePinia()
			const { acceptedLanguages } = pinia.get('client')?.setting
			const { data, meta } = value
			const { editor } = meta
			const editorSchemas =
				editor.schemas
					.reduce((a, c) =>
						Object.assign(a, {
							[c.id]: c,
						}), {})
			return data.length
				? Object.assign(value, {
					data:
						data
							.map(x => {
								const { documentTranslations, schemaId } = x
								if (documentTranslations) {
									Object.assign(x, {
										documentTranslations:
											documentTransform({
												acceptedLanguages: acceptedLanguages,
												documentTranslations,
												schema: editorSchemas[schemaId],
											}),
									})
								}
								return x
							})
				})
				: value
		},
		// tags(value) {
		// 	const { data = [] } = value
		// 	return data.length
		// 		? Object.assign(value, {
		// 			data: data.map(setElementTags)
		// 		})
		// 		: setElementTags(value)
		// }
	}
}

const transformEditor = compose(...values(fieldFunctions.editor))
const transformElement = compose(...values(fieldFunctions.element))
export const transform = compose(transformEditor, transformElement)
