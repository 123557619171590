import {getIds, getTags, mapChildrenIds, stringifyDocumentTranslations} from '@/common/code'
import {notEmpty} from '@/common/validators'

export default {
	_actions: {
		end: x => ({
			input: {
				...x,
			}
		})
	},
	children: {
		default: [],
		rename: 'childrenPosition',
		action: mapChildrenIds,
	},
	clientCards: {
		default: [],
		rename: 'clientCardIds',
		action: getIds,
	},
	clientMedias: {
		default: [],
		rename: 'clientMediaIds',
		action: getIds,
	},
	contentTexts: {
		default: [],
		rename: 'contentTextIds',
		action: getIds,
	},
	documentTranslations: {
		default: {},
		rename: 'data',
		action: stringifyDocumentTranslations,
	},
	parentId: {
		default: '',
		rename: 'parent',
	},
	schemaId: {
		default: '',
		validator: {
			test: notEmpty,
			message: 'SchemaId is invalid',
		},
	},
	slug: {
		default: '',
	},
	state: {
		default: 'development',
	},
	tags: {
		default: [],
		action: getTags,
	},
}
