<template>
	<div class="layout-topbar p-shadow-4">
		<div class="layout-topbar-left">
			<a
				class="layout-topbar-logo"
				href="/#/"
			>
				<img
					src="./assets/images/dummy-logo.png"
					alt=""
					height="40"
				>
			</a>

			<a
				v-ripple
				class="layout-menu-button p-shadow-6 p-ripple"
				@click="onMenuButtonClick($event)"
			>
				<i class="pi pi-chevron-right" />
			</a>

			<a
				v-ripple
				class="layout-topbar-mobile-button p-ripple"
				@click="onTopbarMobileButtonClick($event)"
			>
				<i class="pi pi-ellipsis-v fs-large" />
			</a>
		</div>

		<div
			class="layout-topbar-right"
			:class="{'layout-topbar-mobile-active': mobileTopbarActive}"
		>
			<div class="layout-topbar-actions-left">
				<MegaMenu
					v-if='client.id'
					:model="items"
					class="layout-megamenu"
				/>
			</div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">
					<li class="layout-topbar-item layout-search-item">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							:class="{ 'anchor-disabled': !isUndoAvailable }"
							@click="onTopbarItemClick($event, 'undo')"
						>
							<i
								class="pi pi-undo fs-large"
								:class="{ 'p-disabled': !isUndoAvailable }"
							/>
						</a>
					</li>

					<li class="layout-topbar-item layout-search-item">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							:class="{ 'anchor-disabled': !isRedoAvailable }"
							@click="onTopbarItemClick($event, 'redo')"
						>
							<i
								class="pi pi-undo fs-large icon-flipped"
								:class="{ 'p-disabled': !isRedoAvailable }"
							/>
						</a>
					</li>

					<li class="layout-topbar-item layout-search-item">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'search')"
						>
							<i class="pi pi-search fs-large" />
						</a>

						<transition
							name="layout-search-panel"
							@enter="onEnter"
						>
							<div
								v-show="searchActive"
								class="layout-search-panel p-inputgroup"
								@click="onSearchContainerClick"
							>
								<span class="p-inputgroup-addon"><i class="pi pi-search" /></span>
								<InputText
									ref="searchInput"
									type="text"
									placeholder="Search"
									@keydown="onSearchKeydown($event)"
								/>
								<span class="p-inputgroup-addon">
                  <Button
										icon="pi pi-times"
										class="p-button-rounded p-button-text p-button-plain"
										@click="changeSearchActive"
									/>
                </span>
							</div>
						</transition>
					</li>

					<li class="layout-topbar-item notifications">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'notifications')"
						>
              <span
								v-badge.warning
								class="p-overlay-badge"
							>
                <i class="pi pi-bell fs-large" />
              </span>
						</a>

						<transition name="layout-topbar-action-panel">
							<ul
								v-show="activeTopbarItem === 'notifications'"
								class="layout-topbar-action-panel p-shadow-6"
							>
								<li class="p-mb-3">
									<span class="p-px-3 fs-small">You have <b>4</b> new notifications</span>
								</li>
								<li class="layout-topbar-action-item">
									<div class="p-d-flex p-flex-row p-ai-center">
										<img src="assets/demo/images/avatar/amyelsner.png">
										<div
											class="p-d-flex p-flex-column"
											:class="{'p-ml-3': !isRTL, 'p-mr-3': isRTL}"
											style="flex-grow: 1;"
										>
											<div class="p-d-flex p-ai-center p-jc-between p-mb-1">
												<span class="fs-small p-text-bold">Jerome Bell</span>
												<small>42 mins ago</small>
											</div>
											<span class="fs-small">How to write content about your photographs?</span>
										</div>
									</div>
								</li>
								<li class="layout-topbar-action-item">
									<div class="p-d-flex p-flex-row p-ai-center">
										<img src="assets/demo/images/avatar/annafali.png">
										<div
											class="p-d-flex p-flex-column"
											:class="{'p-ml-3': !isRTL, 'p-mr-3': isRTL}"
											style="flex-grow: 1;"
										>
											<div class="p-d-flex p-ai-center p-jc-between p-mb-1">
												<span class="fs-small p-text-bold">Cameron Williamson</span>
												<small>48 mins ago</small>
											</div>
											<span class="fs-small">Start a blog to reach your creative peak.</span>
										</div>
									</div>
								</li>
								<li class="layout-topbar-action-item">
									<div class="p-d-flex p-flex-row p-ai-center">
										<img src="assets/demo/images/avatar/bernardodominic.png">
										<div
											class="p-d-flex p-flex-column"
											:class="{'p-ml-3': !isRTL, 'p-mr-3': isRTL}"
											style="flex-grow: 1;"
										>
											<div class="p-d-flex p-ai-center p-jc-between p-mb-1">
												<span class="fs-small p-text-bold">Anna Miles</span>
												<small>1 day ago</small>
											</div>
											<span class="fs-small">Caring is the new marketing</span>
										</div>
									</div>
								</li>
								<li class="layout-topbar-action-item">
									<div class="p-d-flex p-flex-row p-ai-center">
										<img src="assets/demo/images/avatar/stephenshaw.png">
										<div
											class="p-d-flex p-flex-column"
											:class="{'p-ml-3': !isRTL, 'p-mr-3': isRTL}"
											style="flex-grow: 1;"
										>
											<div class="p-d-flex p-ai-center p-jc-between p-mb-1">
												<span class="fs-small p-text-bold">Arlene Mccoy</span>
												<small>4 day ago</small>
											</div>
											<span class="fs-small">Starting your traveling blog with Vasco.</span>
										</div>
									</div>
								</li>
							</ul>
						</transition>
					</li>

					<li class="layout-topbar-item app">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'apps')"
						>
							<i class="pi pi-table fs-large" />
						</a>

						<transition name="layout-topbar-action-panel">
							<div
								v-show="activeTopbarItem === 'apps'"
								class="p-grid p-nogutter layout-topbar-action-panel p-shadow-6"
							>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-image action indigo-bgcolor white-color" />
										<span>Products</span>
									</a>
								</div>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-file-pdf action orange-bgcolor white-color" />
										<span>Reports</span>
									</a>
								</div>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-dollar action teal-bgcolor white-color" />
										<span>Balance</span>
									</a>
								</div>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-cog action pink-bgcolor white-color" />
										<span>Settings</span>
									</a>
								</div>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-key action bluegrey-bgcolor white-color" />
										<span>Credentials</span>
									</a>
								</div>
								<div class="layout-topbar-action-item p-col-4">
									<a
										v-ripple
										class="p-d-flex p-ai-center p-jc-center p-flex-column text-color p-ripple"
									>
										<i class="pi pi-sitemap action cyan-bgcolor white-color" />
										<span>Sitemap</span>
									</a>
								</div>
							</div>
						</transition>
					</li>

					<li class="layout-topbar-item">
						<a
							v-ripple
							class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'profile')"
						>
							<img
								src="assets/demo/images/avatar/amyelsner.png"
								alt="avatar"
								style="width: 32px; height: 32px;"
							>
						</a>

						<transition name="layout-topbar-action-panel">
							<ul
								v-show="activeTopbarItem === 'profile'"
								class="layout-topbar-action-panel p-shadow-6"
							>
								<li class="layout-topbar-action-item">
									<a
										v-ripple
										class="p-d-flex p-flex-row p-ai-center p-ripple"
									>
										<i
											class="pi pi-cog"
											:class="{'p-mr-2': !isRTL, 'p-ml-2': isRTL}"
										/>
										<span>Settings</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a
										v-ripple
										class="p-d-flex p-flex-row p-ai-center p-ripple"
									>
										<i
											class="pi pi-file-o"
											:class="{'p-mr-2': !isRTL, 'p-ml-2': isRTL}"
										/>
										<span>Terms of Usage</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a
										v-ripple
										class="p-d-flex p-flex-row p-ai-center p-ripple"
									>
										<i
											class="pi pi-compass"
											:class="{'p-mr-2': !isRTL, 'p-ml-2': isRTL}"
										/>
										<span>Support</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a
										v-ripple
										class="p-d-flex p-flex-row p-ai-center p-ripple"
									>
										<i
											class="pi pi-power-off"
											:class="{'p-mr-2': !isRTL, 'p-ml-2': isRTL}"
										/>
										<span>Logout</span>
									</a>
								</li>
							</ul>
						</transition>
					</li>

					<li class="layout-topbar-item">
						<a
							v-ripple
							class="layout-topbar-action rounded-circle p-ripple"
							@click="onRightPanelButtonClick($event)"
						>
							<i
								class="pi fs-large"
								:class="{'pi-arrow-left': !isRTL, 'pi-arrow-right': isRTL}"
							/>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
	props: {
		isUndoAvailable: {
			type: Boolean,
			default: false,
		},
		isRedoAvailable: {
			type: Boolean,
			default: false,
		},
		horizontal: {
			type: Boolean,
			default: false
		},
		topbarMenuActive: {
			type: Boolean,
			default: false
		},
		activeTopbarItem: String,
		mobileTopbarActive: Boolean,
		searchActive: Boolean
	},
	emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle', 'undo', 'redo'],
	setup() {
		const store = useStore()
		const router = useRouter()
		const client = computed(() => store.getters['useClient/getClient'])
		const identifier = computed(() => store.getters['useClient/getClientIdentifier'])

		const goToPage = ({ name }) => {
			const params = { identifier: identifier.value }
			router.push({ name, params })
		}

		let searchText = ''
		const items = ref([
			{
				items: [],
				label: 'Schemas',
				name: 'schema',
				command: () => goToPage({ name: 'schema'})
			},
			{
				items: [],
				label: 'Informations',
				name: 'information',
				command: () => goToPage({ name: 'information'})
			},
			{
				items: [],
				label: 'Medias',
				name: 'media',
				command: () => goToPage({ name: 'media'})
			},
			{
				items: [],
				label: 'Contenus',
				name: 'content',
				command: () => goToPage({ name: 'content'})
			},
			{
				items: [],
				label: 'Sections',
				name: 'section',
				command: () => goToPage({ name: 'section'})
			},
			{
				items: [],
				label: 'Tutoriels',
				name: 'tutorial',
				command: () => goToPage({ name: 'tutorial'})
			},
			{
				items: [],
				label: 'Checkpoints',
				name: 'checkpoint',
				command: () => goToPage({ name: 'checkpoint'})
			},
			{
				items: [],
				label: 'Categories',
				name: 'category',
				command: () => goToPage({ name: 'category'})
			},
			{
				items: [],
				label: 'Events & Timelines',
				name: 'event',
				command: () => goToPage({ name: 'event'})
			},
			{
				items: [],
				label: 'Paramètres',
				name: 'setting'
			}
		])

		return {
			client,
			goToPage,
			identifier,
			items,
			router,
			searchText,
			store,
		}
	},
	computed: {
		topbarItemsClass() {
			return ['topbar-items animated fadeInDown', {
				'topbar-items-visible': this.topbarMenuActive
			}]
		},
		isRTL() {
			return this.$appState.RTL
		}
	},
	methods: {
		onSearchContainerClick(event) {
			this.$emit("search-click", event)
		},
		changeSearchActive(event) {
			this.$emit('search-toggle', event)
		},
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event)
		},
		onTopbarMenuButtonClick(event) {
			this.$emit('topbar-menubutton-click', event)
		},
		onTopbarItemClick(event, item) {
			if (item === 'undo') {
				this.$emit('undo', event)
			}

			if (item === 'redo') {
				this.$emit('redo', event)
			}

			if(item === 'search') {
				this.$emit('search-toggle', event)
			}

			this.$emit('topbaritem-click', {originalEvent: event, item: item})
		},
		onTopbarMobileButtonClick(event) {
			this.$emit('topbar-mobileactive', event)
		},
		onRightPanelButtonClick(event) {
			this.$emit('rightpanel-button-click', event)
		},
		onSearchKeydown(event) {
			if (event.keyCode == 13) {
				this.$emit('search-toggle', event)
			}
		},
		onEnter() {
			if (this.$refs.searchInput) {
				this.$refs.searchInput.$el.focus()
			}
		}
	}
}
</script>

<style lang='scss'>
.icon-flipped {
	transform: scaleX(-1)
}
.anchor-disabled {
	pointer-events: none;
}
</style>
