const routes = [
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('@/pages/NotFound.vue'),
	},
	{
		path: '/',
		name: 'home',
		exact: true,
		component: () => import('@/components/Dashboard.vue'),
		meta: {
			breadcrumb: [{ label: 'Dashboard' }],
			secure: true,
		},
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		exact: true,
		component: () => import('@/components/Dashboard.vue'),
		meta: {
			breadcrumb: [{ label: 'Dashboard' }],
			secure: true,
		},
	},
	{
		path: '/client',
		name: 'client',
		exact: true,
		component: () => import('@/components/cms/Client.vue'),
		meta: {
			breadcrumb: [{ label: 'Client' }],
			secure: true,
		},
	},
	{
		path: '/schema/:identifier',
		name: 'schema',
		exact: true,
		component: () => import('@/components/cms/Schema.vue'),
		meta: {
			breadcrumb: [{ label: 'Schemas' }],
			secure: true,
		},
	},
	{
		path: '/information/:identifier',
		name: 'information',
		exact: true,
		component: () => import('@/components/cms/Information.vue'),
		meta: {
			breadcrumb: [{ label: 'Informations' }],
			secure: true,
		},
	},
	{
		path: '/media/:identifier',
		name: 'media',
		exact: true,
		component: () => import('@/components/cms/Media.vue'),
		meta: {
			breadcrumb: [{ label: 'Medias' }],
			secure: true,
		},
	},
	{
		path: '/content',
		name: 'content',
		exact: true,
		component: () => import('@/components/cms/Content.vue'),
		meta: {
			breadcrumb: [{ label: 'Contenus' }],
			secure: true,
		},
	},
	{
		path: '/section',
		name: 'section',
		exact: true,
		component: () => import('@/components/cms/Section.vue'),
		meta: {
			breadcrumb: [{ label: 'Section' }],
			secure: true,
		},
	},
	{
		path: '/tutorial',
		name: 'tutorial',
		exact: true,
		component: () => import('@/components/cms/Tutorial.vue'),
		meta: {
			breadcrumb: [{ label: 'Tutoriel' }],
			secure: true,
		},
	},
	{
		path: '/checkpoint',
		name: 'checkpoint',
		exact: true,
		component: () => import('@/components/cms/Checkpoint.vue'),
		meta: {
			breadcrumb: [{ label: 'Checkpoint' }],
			secure: true,
		},
	},
	{
		path: '/event',
		name: 'event',
		exact: true,
		component: () => import('@/components/cms/Event&Timeline.vue'),
		meta: {
			breadcrumb: [{ label: 'Event' }],
			secure: true,
		},
	},
	{
		path: '/category',
		name: 'category',
		exact: true,
		component: () => import('@/components/cms/Category.vue'),
		meta: {
			breadcrumb: [{ label: 'Category' }],
			secure: true,
		},
	},
	{
		path: '/setting',
		name: 'setting',
		exact: true,
		component: () => import('@/components/cms/Setting.vue'),
		meta: {
			breadcrumb: [{ label: 'Paramètres' }],
			secure: true,
		},
	},
	{
		path: '/favorites/dashboardanalytics',
		name: 'dashboardanalytics',
		exact: true,
		component: () => import('@/components/DashboardAnalytics.vue'),
		meta: {
			breadcrumb: [{ label: 'Dashboard Analytics' }],
		},
	},
	{
		path: '/formlayout',
		name: 'formlayout',
		component: () => import('@/components/FormLayoutDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
		},
	},
	{
		path: '/invalidstate',
		name: 'invalidstate',
		component: () => import('@/components/InvalidStateDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
		},
	},
	{
		path: '/input',
		name: 'input',
		component: () => import('@/components/InputDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
		},
	},
	{
		path: '/floatlabel',
		name: 'floatlabel',
		component: () => import('@/components/FloatLabelDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
		},
	},
	{
		path: '/button',
		name: 'button',
		component: () => import('@/components/ButtonDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
		},
	},
	{
		path: '/table',
		name: 'table',
		component: () => import('@/components/TableDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
		},
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('@/components/ListDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
		},
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: () => import('@/pages/TimelineDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
		},
	},
	{
		path: '/tree',
		name: 'tree',
		component: () => import('@/components/TreeDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
		},
	},
	{
		path: '/panel',
		name: 'panel',
		component: () => import('@/components/PanelsDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
		},
	},
	{
		path: '/overlay',
		name: 'overlay',
		component: () => import('@/components/OverlaysDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
		},
	},
	{
		path: '/media0',
		name: 'media0',
		component: () => import('@/components/MediaDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
		},
	},
	{
		path: '/menus',
		component: () => import('@/components/MenusDemo.vue'),
		children: [
			{
				path: '',
				component: () => import('@/components/menu/PersonalDemo.vue'),
				meta: {
					breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
				},
			},
			{
				path: '/menus/seat',
				component: () => import('@/components/menu/SeatDemo.vue'),
				meta: {
					breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
				},
			},
			{
				path: '/menus/payment',
				component: () => import('@/components/menu/PaymentDemo.vue'),
				meta: {
					breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
				},
			},
			{
				path: '/menus/confirmation',
				component: () => import('@/components/menu/ConfirmationDemo.vue'),
				meta: {
					breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
				},
			},
		],
	},
	{
		path: '/messages',
		name: 'messages',
		component: () => import('@/components/MessagesDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
		},
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('@/components/FileDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
		},
	},
	{
		path: '/chart',
		name: 'chart',
		component: () => import('@/components/ChartsDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
		},
	},
	{
		path: '/misc',
		name: 'misc',
		component: () => import('@/components/MiscDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
		},
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import('@/utilities/Icons.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
		},
	},
	{
		path: '/widgets',
		name: 'widgets',
		component: () => import('@/utilities/Widgets.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
		},
	},
	{
		path: '/grid',
		name: 'grid',
		component: () => import('@/utilities/GridDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
		},
	},
	{
		path: '/spacing',
		name: 'spacing',
		component: () => import('@/utilities/SpacingDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
		},
	},
	{
		path: '/elevation',
		name: 'elevation',
		component: () => import('@/utilities/ElevationDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
		},
	},
	{
		path: '/typography',
		name: 'typography',
		component: () => import('@/utilities/Typography.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
		},
	},
	{
		path: '/display',
		name: 'display',
		component: () => import('@/utilities/DisplayDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
		},
	},
	{
		path: '/flexbox',
		name: 'flexbox',
		component: () => import('@/utilities/FlexBoxDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
		},
	},
	{
		path: '/text',
		name: 'text',
		component: () => import('@/utilities/TextDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
		},
	},
	{
		path: '/crud',
		name: 'crud',
		component: () => import('@/pages/CrudDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
		},
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('@/pages/CalendarDemo.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
		},
	},
	{
		path: '/invoice',
		name: 'invoice',
		component: () => import('@/pages/Invoice.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
		},
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('@/pages/Help.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Help' }],
		},
	},
	{
		path: '/empty',
		name: 'empty',
		component: () => import('@/components/EmptyPage.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
		},
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: () => import('@/components/Documentation.vue'),
		meta: {
			breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/Login.vue'),
		meta: {
			secure: false,
		}
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('@/pages/Error.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import('@/pages/NotFound.vue')
	},
	{
		path: '/access',
		name: 'access',
		component: () => import('@/pages/Access.vue')
	},
	{
		path: '/landing',
		name: 'landing',
		component: () => import('@/pages/Landing.vue')
	},
	{
		path: '/contactus',
		name: 'contactus',
		component: () => import('@/pages/ContactUs.vue')
	}
]

export default routes
