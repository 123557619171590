<template>
	<Panel
		toggleable
		collapsed
		class="p-col-12"
	>
		<template #header>
			<div style='width:100%;'>
				<Toolbar>
					<template #start>
						<div style="width:100px;">
							<Tag class='tag-black'>Event</Tag>
						</div>
						<Inplace :closable='true'>
							<template #display>
								<Tag
									class='tag-green'
								>{{ refEvent.slug }}</Tag>
							</template>
							<template #content>
								<InputText
									v-model='refEvent.slug'
								/>
							</template>
						</Inplace>
					</template>

<!--					<template #end>
						<Button
							icon='pi pi-trash'
							class='p-button-success p-mr-2'
							v-tooltip.bottom='`Delete`'
							@click.stop='confirmDelete(refEvent)'
						/>
						<Button
							icon='pi pi-save'
							class='p-button-success p-mr-2'
							v-tooltip.bottom='`Save Event`'
							@click.stop='save(refEvent)'
						/>
					</template>-->
				</Toolbar>
			</div>
		</template>

		<div
			v-for='(_, fieldName) of refEvent'
			:key="`${fieldName}-${refEvent.id}`"
			class="p-grid p-fluid"
		>
			<div class="p-col-12">
				<component
					:field-name="fieldName"
					:is="fieldName"
					:schemaId="refEvent.schemaId"
					@changeSchemaId="changeSchemaId"
					@update:modelValue="onChange(fieldName, $event)"
					v-if="isComponent(fieldName)"
					v-model:meta="refMeta"
					v-model="refEvent[fieldName]"
				/>
			</div>
		</div>
	</Panel>
</template>

<script>
import { computed, ref, watchEffect } from 'vue'
import {
	// elementDeleted,
	entries,
	isComponent,
	isDocumentTranslations,
	getRenamedProperties, toastError, elementDeleted,
} from '@/common/code'
import {capFirst, timeUnitsFromSeconds} from '@affordancestudio/functions'
import { usePinia } from '@/stores/pinia/main'
import {cloneDeep, set} from 'lodash'
import {structures} from '@/components/cms/structures/structures'
import {useToast} from 'primevue/usetoast'
import {inject} from 'vue'
import {saveItem} from '@/common/crud/save'
import {confirmDeleteElement} from '@/common/crud/delete'
import {useConfirm} from 'primevue/useconfirm'

export default {
	name: 'event',
	props: {
		meta: {
			type: Object,
			default: () => ({}),
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		//	*******************************************************************************************
		//	INJECT
		//	*******************************************************************************************
		const boxPost = inject('$boxPost')

		//	*******************************************************************************************
		//	USE
		//	*******************************************************************************************

		const pinia = usePinia()
		const toast = useToast()
		const confirm = useConfirm()

		//	*******************************************************************************************
		//	CONSTANTS
		//	*******************************************************************************************

		const CREATE_CLIENT_EVENT = 'createClientEvent'
		const DELETE_CLIENT_EVENT = 'deleteClientEvent'
		const UPDATE_CLIENT_EVENT = 'updateClientEvent'
		const CONFIRM_DIALOG_GROUP = 'event'
		const { acceptedLanguages } = pinia.get('client')?.setting
		const { get } = pinia

		//	*******************************************************************************************
		//	REF
		//	*******************************************************************************************

		const refEvent = ref({})
		const refMeta = ref({})

		//	*******************************************************************************************
		//	COMPUTED
		//	*******************************************************************************************

		const clientIdentifier = computed(() => get('client')?.identifier)
		const schemas = computed(() =>
			refMeta?.value?.editor?.value?.schemas
				? getRenamedProperties(refMeta.value.editor.value.schemas, { id: 'code', slug: 'name' })
				: []
		)

		//	*******************************************************************************************
		//	WATCH
		//	*******************************************************************************************

		watchEffect(() => {
			refEvent.value = props.modelValue ?? []
		})

		watchEffect(() => {
			refMeta.value = props.meta
		})

		//	*******************************************************************************************
		//	FUNCTIONS
		//	*******************************************************************************************

		const confirmDelete = data => {
			const value = cloneDeep(data)
			const client = clientIdentifier.value
			const funcEnd = elementDeleted({ toast })
			const funcRefresh = () => {
				pinia.addRefresh('Event')
				pinia.addRefresh('Timeline')
				pinia.refresh({ client })
			}
			const group = CONFIRM_DIALOG_GROUP
			const mutation = DELETE_CLIENT_EVENT

			const { event = {} } = value
			const { slug } = event

			if (slug) Object.assign(value, { slug })

			confirmDeleteElement({
				value,
			},
			{
				boxPost,
				client,
				confirm,
				funcEnd,
				funcRefresh,
				group,
				mutation,
			})
		}

		const onChange = (fieldName, value) => {
			Object.assign(refEvent.value, {
				[fieldName]: value
			})
			emit('update:modelValue', refEvent.value)
		}

		const onUpdateMeta = (meta, path, value) => {
			set(meta.editor, path, value)
		}

		const save = data => {
			const { elementType } = data
			const model = elementType.split('::').join('')
			const structure = structures[model]
			const client = clientIdentifier.value
			const mutations = {
				create: CREATE_CLIENT_EVENT,
				update: UPDATE_CLIENT_EVENT,
			}

			if (!structure) {
				toast.add({
					severity: 'error',
					summary: 'Save item',
					detail: 'No data structure found',
					life: 4000,
				})
				return
			}

			saveItem({
				client,
				data,
				mutations,
				structure,
			},
			{
				refresh: () => {
					pinia.addRefresh('Event')
					pinia.addRefresh('Timeline')
					pinia.refresh({ client })
				},
				boxPost,
				toast,
				toastError: toastError({ toast }),
			})
		}

		const displayTimeUnits = value =>
			entries(timeUnitsFromSeconds(value))
				.reduce((a, [ unit, value ]) =>
					`${a}${ a ? ' ' : ''} ${value}${unit}`
				, '')

		const updateTimelineTimeUnits = (object, field, value) => {
			Object.assign(object, {
				[field]: value,
			})
		}

		const showMedias = data => {
			console.log(data)
			// debugger
		}

		const changeSchemaId = schemaId => {
			Object.assign(refEvent.value, {
				schemaId,
			})
		}

		//	*******************************************************************************************
		//	RETURN
		//	*******************************************************************************************

		return {
			CONFIRM_DIALOG_GROUP,
			acceptedLanguages,
			capFirst,
			confirmDelete,
			displayTimeUnits,
			isComponent,
			isDocumentTranslations,
			onChange,
			onUpdateMeta,
			refEvent,
			refMeta,
			save,
			changeSchemaId,
			schemas,
			showMedias,
			updateTimelineTimeUnits,
		}
	}
}
</script>

<style scoped lang='scss'>
.tag-black {
	background-color: #333;
}
.tag-field {
	font-size: 1.1rem;
}
.tag-language {
	font-size: 1.2rem;
}
.tag-blue {
	background-color: #458df1;
	font-size: 1.1rem;
}

.tag-green {
	background-color: #69a138;
}

.p-multiselect {
	width: 100%;
}

::v-deep(.multiselect-custom) {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);

		img.flag {
			width: 17px;
		}
	}
}

@media screen and (max-width: 640px) {
	.p-multiselect {
		width: 100%;
	}
}

.card {
	background: #ffffff;
	padding: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
	border-radius: 4px;
	margin-bottom: 2rem;
}
.p-dropdown {
	width: 14rem;
	font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	img {
		width: 50px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid var(--surface-border);

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}

.image-container {
	&.audio {
		width: 100%;
	}

	&.tiny {
		width: 200px;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 180px;
			height: 50px;
		}

		> .lottie {
			width: 100px;
			height: 50px;
		}

		> .pdf {
			width: 100px;
			height: 100px;
		}
	}
	&.small {
		width: 300px;
		height: 200px;
		margin-right: 1rem;

		> .image {
			background-color: #eee;
			padding: 4px;
			max-width: 200px;
			max-height: 200px;
		}

		> .lottie {
			width: 200px;
			height: 200px;
		}

		> .pdf {
			width: 200px;
			height: 200px;
		}
	}

	&.big,
	&.galleria {
		width: 100%;
		height: 300px;

		>.image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #eee;
		}

		> .lottie {
			width: 100%;
			height: 100%;
		}

		> .pdf {
			width: 100%;
			height: 100%;
		}
	}

	&.zoomed {
		width: 100%;
		height: 100%;

		> .image {
			background-color: #eee;
			width: 100%;
			height: 100%;
		}

		> .lottie {
			height: 100%;
		}

		> .pdf {
			width: 400px;
			height: 600px;
		}
	}
}
.schema-dropdown {
	display: flex;
	align-items: center;
}
</style>
