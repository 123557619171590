import { cloneDeep } from 'lodash'
import { mutate } from '@/stores/functions'

export const useClient = {
	namespaced: true,
	state() {
		return {
			lastClient: {},
			currentClient: {},
		}
	},
	getters: {
		getAcceptedLanguages: state => state.currentClient?.setting?.acceptedLanguages || [],
		getLocales: state => state.currentClient?.setting?.acceptedLanguages || [],
		getAvailableLocales: state => state.currentClient?.availableLocales || [],
		getClient: state => state.currentClient || {},
		getClientIdentifier: state => state.currentClient.identifier || '',
		getLastClientIdentifier: state => state.lastClient.identifier || '',
		getSettings: state => state.currentClient?.setting || [],
		// getUndoMutation: state => state.undoMutation,
	},
	mutations: {
		setLastClient(state) {
			state.lastClient = cloneDeep(state.currentClient)
		},
		setClient(state, payload) {
			state.lastClient = cloneDeep(state.currentClient)
			state.currentClient = cloneDeep(payload)
		},
		setIdentifier(state, payload) {
			state.currentClient.identifier = payload
		},
		setSettings(state, { value }) {
			state.currentClient.setting = value
		},
		mutate(state, {
			field,
			newEdited,
			newValue,
			parent,
			path,
			rootState,
			route,
			scrollTop,
		}) {
			mutate({
				field,
				newEdited,
				newValue,
				parent,
				path,
				rootState,
				route,
				scrollTop,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			field,
			newEdited = true,
			parent,
			path,
			route,
			scrollTop:_scrollTop,
			value:newValue,
		}) {
			const { value:scrollTop = 0 } = _scrollTop
			commit('mutate', {
				field,
				newEdited,
				newValue,
				parent,
				path,
				rootState,
				route,
				scrollTop,
			})
		},
	},
}
