import { mutate } from '@/stores/functions'
import { getSpecificInfo } from '@/common/editor.js'
import { reactive } from 'vue'
import { getRandomID } from '@affordancestudio/functions'
import { getIds } from '@/common/code'

const reset = () => ({
	editor: {},
	categories: [],
})

export const useCategories = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getCategories: state => state.categories,
		// getParents: state => state.parents,
		getEditor: state => state.editor,
		getStates: state => state.editor?.state ?? [],
		getSchemaById: state => id => (state?.editor?.schemas ?? []).find(schema => schema.id === id),
	},
	mutations: {
		changeField(state, payload) {
			const { id, language, value } = payload
			const found =
				state.categories
					.find(({ id:ID }) => id === ID)
			if (!found) return
			Object.assign(found.documentTranslations[language], {
				...value,
			})
		},
		addCategory(state, acceptedLanguages) {
			const newCategory = reactive({
				ancestorIds: [],
				children: [],
				clientCardIds: [],
				contentTextIds: [],
				clientMediaIds: [],
				documentTranslations:
					acceptedLanguages
						.reduce((a, language) =>
							Object.assign(a, {
								[language]: {},
							}), {}),
				elementType: 'Client::Category',
				id: getRandomID(10),
				parentId: null,
				schemaId: null,
				slug: '0000000000',
				tags: [],
			})
			state.categories = [ newCategory, ...state.categories ]
		},
		deleteCurrentItem(state, { id }) {
			state.categories =
				state.categories
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		async setCurrentCategories(state, { payload }) {
			const { data:categories, meta } = payload
			const { editor } = meta

			if (editor.specificInfo) {
				Object.assign(editor.specificInfo, {
					...getSpecificInfo({ editor })
				})
			}
			
			state.editor = editor
			state.categories = categories
				.map(x => {
					const { ancestors } = x
					return Object.assign(x, {
						ancestorIds: getIds(ancestors),
					})
				})
				.sort((a, b) => a.slug.localeCompare(b.slug))
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
