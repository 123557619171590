import {getIds, getTags, stringifyDocumentTranslations} from '@/common/code'
import {notEmpty} from '@/common/validators'

export default {
	_actions: {
		end: x => ({
			input: {
				...x,
			}
		})
	},
	slug: {
		default: '',
	},
	tags: {
		default: [],
		action: getTags,
	},
	clientMedias: {
		default: [],
		rename: 'clientMediaIds',
		action: getIds,
	},
	documentTranslations: {
		default: {},
		rename: 'data',
		action: stringifyDocumentTranslations,
	},
	schemaId: {
		default: '',
		validator: {
			test: notEmpty,
			message: 'SchemaId is invalid',
		},
	},
	duration: {
		default: 0,
	},
}
