import { get, isEqual, set } from 'lodash'

export const mutate = ({
	_extra,
	field,
	func,
	newEdited,
	newParentEdited,
	newParentTab,
	newValue,
	parent,
	path,
	rootState,
	route,
	state,
}) => {
	/*
	GET PREVIOUS VALUE
	 */
	let previousValue = null
	let previousEdited = null
	const previousParentEdited =
		parent
			? get(parent, '_edited')
			: null
	const previousParentTab =
		parent
			? get(parent, 'tab') ?? 0
			: 0

	if (field && path) {
		previousEdited = get(field, '_edited')
		previousValue = get(field, path)
	}	else if (field) {
		const propsField = Object.keys(field)
		const propsNewValue = Object.keys(newValue)
		previousEdited = get(field, '_edited')
		previousValue =
			propsNewValue
				.reduce((a, property) =>
					Object.assign(a, {
						[property]: propsField.includes(property)
							? field[property]
							: undefined
					}), {})
	}	else if (path) {
		previousEdited = get(state, '_edited')
		previousValue = get(state, path)
	}
	if (isEqual(previousValue, newValue)) return
	/*
	SET UNDO
	 */
	if (route) {
		rootState.undoMutation = {
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newValue,
			parent,
			path,
			previousEdited,
			previousParentEdited,
			previousParentTab,
			previousValue,
			route,
		}
		if (_extra) Object.assign(rootState.undoMutation, { _extra })
	}

	/*
	SET NEW VALUE
	 */
	const setNewValues = () => {
		if (parent) {
			set(parent, '_edited', newParentEdited)
			set(parent, 'tab', newParentTab)
		}
		if (field && path) {
			set(field, path, newValue)
			if (!parent) set(field, '_edited', newEdited)
			if (_extra) set(field, '_extra', _extra)
		} else if (field) {
			switch (typeof newValue) {
			case 'object':
				Object.entries(newValue ?? {})
					.forEach(([ property, value ]) => {
						if (typeof value === 'undefined') {
							delete field[property]
							delete field._extra
						} else {
							Object.assign(field, {
								[property]: value,
								_edited: newEdited,
							})
							if (_extra) Object.assign(field, { _extra })
						}
					})
				break
			default:
				if (field?.value) {
					Object.assign(field, {
						value: newValue,
						_edited: newEdited,
					})
					field.value = newValue
					if (_extra) Object.assign(field, { _extra })
				}
				else field = newValue
				break
			}
		} else if (path) {
			set(state, path, newValue)
			set(state, '_edited', newEdited)
			if (_extra) set(state, '_extra', _extra)
		}
	}

	setNewValues()

	const object =
		field
			? field
			: parent
				? parent
				: null

	if (!route && object) {
		let flashPath = '_flashBackground'
		flashPath +=
			path
				? `.${path}`
				: ''
		set(object, flashPath, true)
		setTimeout(() => delete object._flashBackground, 500)
	}
}
