<template>
	<div class="p-col-3">
		<Panel
			toggleable
			collapsed
		>
			<template #header>
				<h4>Slug</h4>
			</template>
			<Inplace :closable='true'>
				<template #display>
					<Tag
						class='tag-green'
					>{{ refValue }}</Tag>
				</template>
				<template #content>
					<InputText
						v-model='refValue'
					/>
				</template>
			</Inplace>
		</Panel>
	</div>
</template>

<script>
import { ref, watchEffect } from 'vue'

export default {
	name: 'slug',
	props: {
		fieldName: {
			type: String,
			default: () => '',
		},
		modelValue: {
			type: String,
			default: () => '',
		}
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const refValue = ref()

		watchEffect(() => refValue.value = props.modelValue)

		const onChange = value => {
			emit('update:modelValue', value)
		}

		return {
			onChange,
			refValue
		}
	}
}
</script>

<style scoped lang="scss">
.tag-green {
	background-color: #69a138;
}
</style>
