import {confirmDialogModal, elementDeleted, toastError} from '@/common/code'
import {engage} from '@affordancestudio/engage-cms'

export const removeStoreElement = ({ store, namespace }) => ({ id }) => () => {
	store.commit(`${namespace}/deleteCurrentElement`, { id })
}

export const confirmDeleteElement = ({
	value,
},
{
	boxPost,
	client,
	confirm,
	extra = {},
	funcEnd = x => x,
	funcLocalDelete = x => x,
	funcRefresh = x => x,
	funcStoreDelete = x => x,
	group,
	mutation,
}) => {
	const { id, slug } = value
	confirmDialogModal({
		confirm,
		group,
		header: 'Permanent Deletion Confirmation',
		message: `Delete [${slug}]?`,
		accept: async () => {
			const variables = Object.assign({ id }, { ...extra })
			;(await boxPost(mutation, { client, variables }))
				.map(funcLocalDelete)
				.map(funcStoreDelete)
				.map(funcRefresh)
				.fold(toastError, funcEnd)
		},
	})
}

export const confirmDeleteItem = ({
	value
},
{
	client,
	collection,
	confirm,
	mutation,
	removeStoreItem = () => {},
	taskPost,
	toast,
}) => {
	const { id, slug } = value
	confirmDialogModal({
		confirm,
		message: `Delete [${slug}]?`,
		header: 'Permanent Deletion Confirmation',
		accept: () => {
			const variables = { id }
			taskPost(mutation, { client, variables })
				.map(removeLocalItem({ client, collection, id }))
				.map(removeStoreItem({ id }))
				.fork(toastError, elementDeleted(toast))
		},
	})
}

const removeLocalItem = ({ client, collection, id }) => () => {
	engage.deleteItem({ client, collection, id })
}
