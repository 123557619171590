<template>
	<div class='pages-body login-page p-d-flex p-flex-column'>
		<div class='topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center'>
			<div
				class='topbar-left p-ml-3 p-d-flex'
				routerLink='/'
			>
				<div class='logo'>
					<img
						src='assets/layout/images/logo2x.png'
						alt=''
					>
				</div>
			</div>
			<div class='topbar-right p-mr-3 p-d-flex'>
				<Button
					type='button'
					label='DASHBOARD'
					class='p-button-text p-button-plain topbar-button'
					@click='goDashboard'
				/>
			</div>
		</div>

		<div class='p-as-center p-mt-auto p-mb-auto'>
			<div class='pages-panel card p-d-flex p-flex-column'>
				<div class='pages-header p-px-3 p-py-1'>
					<h2>LOGIN</h2>
				</div>

				<h4>Welcome</h4>

				<div class='pages-detail p-mb-6 p-px-6'>
					Please use the form to sign-in Ultima network
				</div>

				<div class='input-panel p-d-flex p-flex-column p-px-3'>
					<div class='p-inputgroup'>
            <span class='p-inputgroup-addon'>
              <i class='pi pi-envelope' />
            </span>
						<span class='p-float-label'>
              <InputText
								id='email'
								v-model='email'
								type='text'
							/>
              <label for='email'>Email</label>
            </span>
					</div>

					<div class='p-inputgroup p-mt-3 p-mb-6'>
            <span class='p-inputgroup-addon'>
              <i class='pi pi-lock' />
            </span>
						<span class='p-float-label'>
              <InputText
								id='password'
								v-model='password'
								type='password'
							/>
              <label for='password'>Password</label>
            </span>
					</div>
				</div>

				<Button
					class='login-button p-mb-6 p-px-3'
					label='LOGIN'
					@click='login'
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
	inject: ['$logIn'],
	setup() {
		const router = useRouter()
		const error = x => {
			return x
		}

		return {
			error,
			LOG_IN_EDITOR: 'logInEditor',
			router,
		}
	},
	data() {
		return {
			email: '',
			password: '',
		}
	},
	methods: {
		async login() {
			const { email, password } = this;
			(await this.$logIn({ email, password }))
				.fold(this.error, this.goDashboard)
		},
		goDashboard() {
			this.router.push({ name: 'dashboard' })
		},
	},
}
</script>

<style scoped>

</style>
