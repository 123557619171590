import { canSaveItem } from '@/common/status'
import {confirmDialogModal, destructure, saveSpecifics} from '@/common/code'
import {isEmpty} from 'lodash'

export const confirmSaveItem = async (value, { confirm, group, save = saveItem, toast }) => {
	if (!canSaveItem(value)) {
		toast.add({
			severity: 'warn',
			summary: 'Save item',
			detail: 'Item not edited.',
			life: 4000,
		})
		return
	}
	confirmDialogModal({
		confirm,
		group,
		message: 'Do you want to save this item?',
		header: 'Saving Confirmation',
		accept: async () => await save(value),
	})
}

export const saveItem = ({ client, data, mutations, structure }, { boxPost, refresh, taskPost, toast, toastError }) => {
	const SET_SPECIFIC2 = 'setSpecific2'
	const error = message =>
		toast.add({
			detail: message,
			life: 3000,
			severity: 'warn',
			summary: 'Saving Item',
		})
	const result = destructure({ data, structure }, { error })
	const { input } = result
	if (!result || !input || isEmpty(input)) return
	
	const { id } = data
	const { create, update } = mutations
	const mutation =
		isNaN(Number(id))
			? update
			: create
	const variables =
		isNaN(Number(id))
			? { id, ...result }
			: { ...result }

	if (boxPost) {
		(async () => {
			(await boxPost(mutation, {client, variables}))
				.map(await saveSpecifics({ boxPost, client, data, mutation: SET_SPECIFIC2 }))
				.map(refresh)
				.fold(toastError, () => {
					toast.add({
						detail: 'Item saved',
						life: 3000,
						severity: 'success',
						summary: 'Saving item',
					})
				})
		})()
	} else if (taskPost) {
		(async () => {
			taskPost(mutation, {client, variables})
				.map(await saveSpecifics({ boxPost, client, data, mutation: SET_SPECIFIC2 }))
				.map(refresh)
				.fork(toastError, () => {
					toast.add({
						detail: 'Item saved',
						life: 3000,
						severity: 'success',
						summary: 'Saving item',
					})
				})
		})()
	}
}
