<template>
	<Panel
		toggleable
		collapsed
	>
		<template #header>
			<h4>{{capFirst(field)}} - {{`${displayTimeUnits(getTotalSeconds()) || '(no values)'}`}}</h4>
		</template>
		<div class="p-grid p-fluid">
			<div
				class="p-col-2"
				v-for="(values, unit) of units"
				:key="unit"
			>
				<h6>{{unit}}</h6>
				<InputNumber
					v-model.number="values.value"
					@update:modelValue="onChange"
				/>
			</div>
		</div>
	</Panel>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { displayTimeUnits, entries } from '@/common/code'
import { capFirst, getSecondsIn } from '@affordancestudio/functions'

export default {
	name: 'TimeUnits',
	props: {
		field: {
			type: String,
			default: () => '',
		},
		modelValue: {
			type: Number,
			default: () => 0,
		}
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const units = ref({
			year: {
				min: 0,
				max: 1,
				getSeconds: value => value * getSecondsIn({ unit: 'y', nb: 1 }),
				value: 0,
			},
			week: {
				min: 0,
				max: 51,
				getSeconds: value => value * getSecondsIn({ unit: 'w', nb: 1 }),
				value: 0,
			},
			day: {
				min: 0,
				max: 31,
				getSeconds: value => value * getSecondsIn({ unit: 'd', nb: 1 }),
				value: 0,
			},
			hour: {
				min: 0,
				max: 23,
				getSeconds: value => value * getSecondsIn({ unit: 'h', nb: 1 }),
				value: 0,
			},
			minute: {
				min: 0,
				max: 59,
				getSeconds: value => value * getSecondsIn({ unit: 'm', nb: 1 }),
				value: 0,
			},
			second: {
				min: 0,
				max: 59,
				getSeconds: value => value,
				value: 0,
			},
		})

		const setTimeUnits = value => {
			let remaining = value

			entries(units.value)
				.forEach(([ , values ]) => {
					const seconds = values.getSeconds(1)
					const number = Math.floor(remaining / seconds)
					values.value = number
					remaining -= number * seconds
				})
		}

		const getTotalSeconds = () =>
			entries(units.value)
				.reduce((a, [ , values ]) => {
					const { getSeconds, value } = values
					return a + getSeconds(value)
				}, 0)

		const onChange = () => {
			emit('update:modelValue', getTotalSeconds())
		}

		watchEffect(() => setTimeUnits(props.modelValue))

		return {
			capFirst,
			displayTimeUnits,
			getTotalSeconds,
			onChange,
			units
		}
	}
}
</script>

<style scoped lang="scss">

</style>
