<template>
	<div class="p-col-3">
		<Panel
			toggleable
			collapsed
		>
			<template #header>
				<h4>Tags<Badge :value='dataTags.length' severity="info" class="p-ml-2 p-mr-2" /></h4>
			</template>
			<span class="p-float-label">
				<InputText
					style='width:100%;'
					type='text'
					@change='addTag'
					v-model="newTag"
				/>
			</span>
			<MultiSelect
				:filter='true'
				:options='metaTags'
				class="multiselect-custom"
				optionLabel='tag'
				placeholder='Select Tags'
				v-if="multiple"
				v-model='dataTags'
			>
				<template #value='slotProps'>
					<div
						class="country-item country-item-value"
						v-for='option of slotProps.value'
						:key='option.code'
					>
						<div>{{ option.tag }}</div>
					</div>
					<template v-if="!slotProps.value || slotProps.value.length === 0">
						Select Tags
					</template>
				</template>
				<template #option='slotProps'>
					<div
						class="country-item"
					>
						{{ slotProps.option.tag }}
					</div>
				</template>
			</MultiSelect>
			<Dropdown
				:filter='true'
				:options='metaTags'
				optionLabel='name'
				placeholder="Select a Tag"
				v-else
				v-model='dataTags'
			/>
		</Panel>
	</div>
</template>

<script>
import { computed, reactive, ref, watchEffect } from 'vue'
import { isObject, uniqBy} from 'lodash'
import {getTag} from '@/common/code'

export default {
	name: 'tags',
	props: {
		meta: {
			type: Object,
			default: () => ({}),
		},
		modelValue: {
			type: Array,
			default: () => [],
		}
	},
	emits: [ 'update:modelValue', 'update:meta' ],
	setup(props, { emit }) {
		const multiple = true

		//	*******************************************************************************************
		//	REF
		//	*******************************************************************************************

		const refMeta = ref({})
		const dataTags = ref([])
		const newTag = ref('')

		//	*******************************************************************************************
		//	WATCH
		//	*******************************************************************************************

		watchEffect(() =>
			dataTags.value =
				props.modelValue
					.map(tag => isObject(tag) ? tag : ({ code: tag, tag }))
		)
		watchEffect(() => refMeta.value = props.meta)

		//	*******************************************************************************************
		//	COMPUTED
		//	*******************************************************************************************

		const metaTags = computed(() => refMeta.value?.editor?.tags?.value ?? [])

		//	*******************************************************************************************
		//	FUNCTIONS
		//	*******************************************************************************************

		const addTag = e => {
			const { value } = e.target
			const tag = {
				code: value,
				tag: value,
			}
			const newEditorTags = uniqBy([
				...metaTags.value,
				reactive(tag),
			], getTag)

			Object.assign(refMeta.value.editor.tags, {
				value: newEditorTags,
			})

			emit('update:meta', refMeta.value)

			const newDataTags = uniqBy([
				...dataTags.value,
				reactive(tag),
			], getTag)

			emit('update:modelValue', newDataTags)

			newTag.value = ''
		}

		//	*******************************************************************************************
		//	RETURN
		//	*******************************************************************************************

		return {
			multiple,
			addTag,
			dataTags,
			metaTags,
			newTag,
		}
	}
}
</script>

<style scoped>

</style>
