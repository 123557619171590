import { spreadName, spreadNameCode } from '@/common/composition.js'
import { computed } from 'vue'

export const getCheckpointSpecificInfo = specificInfo => {
	Object.assign(specificInfo.clientGroups, { value: spreadNameCode(specificInfo?.clientGroups?.value ?? {}) })
	Object.assign(specificInfo.alternatives, { value: spreadName(specificInfo?.alternatives?.value ?? {}) })
	Object.assign(specificInfo.onlyFor, { value: spreadName(specificInfo?.onlyFor?.value ?? {}) })
	Object.assign(specificInfo.specificLanguages, { value: spreadName(specificInfo?.specificLanguages?.value ?? {}) })
	return specificInfo
}

export const getSpecificInfo = ({ editor }) => {
	return editor.specificInfo
}

export const schemas = editor => computed(() =>
	editor.value.schemas
		.map(({ id:code, slug:name }) => ({
			name,
			code
		})))
