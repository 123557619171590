<template>
		<component
			v-for='(content, fieldName) in fields'
			:key='content.id'
			:is='`Type${content.type}`'
			:field='content'
			:fieldName='fieldName'
			:disabled='disabled'
			v-model="fields[fieldName].value"
			@update:modelValue="onChange(fieldName, $event)"
		/>
</template>

<script>
import TypeEditor from '../FieldTypes/Type-Editor'
import TypeInteger from '../FieldTypes/Type-Integer'
import TypeString from '../FieldTypes/Type-String'
import TypeBoolean from '../FieldTypes/Type-Boolean'
import { ref, /*watch,*/ watchEffect } from 'vue'

export default {
	name: 'Fields',
	components: {
		TypeBoolean,
		TypeEditor,
		TypeInteger,
		TypeString,
	},
	props: {
		disabled: {
			type: Boolean,
			default: () => false,
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		meta: {
			type: Object,
			default: () => ({}),
		}
	},
	emits: [ 'update:modelValue' ],
	setup(props, { emit }) {
		const fields = ref({})
		// const validationSchema = ref(null)

		const onChange = (fieldName, value) => {
			fields.value[fieldName].value = value
			emit('update:modelValue', fields.value)
		}

		watchEffect(() => {
			fields.value = props.modelValue
		})

		// watch(fields, (newValue) => {
		// 	validationSchema.value = {
		// 		type: 'object',
		// 		properties: Object.entries(newValue)
		// 			.reduce((a, [ field ]) =>
		// 				Object.assign(a, {
		// 					[field]: {
		// 						type: 'string'//value.type.toLowerCase()
		// 					}
		// 				}), {}),
		// 		required: Object.entries(newValue)
		// 			.reduce((a, [ field, value ]) => value?.needed ? [ ...a, field ] : a, [])
		// 	}
		// })

		return {
			fields,
			onChange,
		}
	},
}
</script>

<style scoped>

</style>
