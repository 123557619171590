import { mutate } from '@/stores/functions'
import { getRandomID, walkThrough } from '@affordancestudio/functions'
import { difference, isEmpty } from 'lodash'
import { reactive } from 'vue'
import { spreadNameCode, spreadName } from '@/common/composition'

const reset = () => ({
	editor: {},
	tutorials: [],
})

export const useTutorials = {
	namespaced: true,
	state() {
		return {
			...reset(),
		}
	},
	getters: {
		getAcceptedLanguages: (state, getters, rootState, rootGetters) => rootGetters['useClient/getLocales'],
		getEditor: state => state.editor,
		getTutorials: state => state.tutorials,
	},
	mutations: {
		addTuto(state, acceptedLanguages) {
			const newTuto = reactive({
				clientMediaIds: [],
				detailsTranslations:
					acceptedLanguages.value
						.reduce((a, language) =>
							Object.assign(a, {
								[language]: {}
							}), {}),
				id: getRandomID(10),
				info: {},
				slug: 'New-Tuto',
				tags: [],
				specificInfo: {
					clientGroups: [],
					guestExclusive: false,
					onlyFor: [],
					alternatives: [],
					specificLanguages: [],
				},
			})
			state.tutorials = [ newTuto, ...state.tutorials ]
		},
		deleteCurrentItem(state, { id }) {
			// debugger
			state.currentContentTexts =
				state.currentContentTexts
					.filter(({ id:ID }) => id !== ID)
		},
		reset(state) {
			Object.assign(state, {
				...reset(),
			})
		},
		async setCurrentTutorials(state, { acceptedLanguages, payload }) {
			const { data, meta } = payload
			const { editor } = meta
			
			Object.assign(editor.specificInfo.clientGroups, { value: spreadNameCode(editor.specificInfo.clientGroups.value) })
			Object.assign(editor.specificInfo.onlyFor, { value: spreadName(editor.specificInfo.onlyFor.value) })
			Object.assign(editor.specificInfo.alternatives, { value: spreadName(editor.specificInfo.alternatives.value) })
			Object.assign(editor.specificInfo.specificLanguages, { value: spreadName(editor.specificInfo.specificLanguages.value) })

			const { specificInfo } = editor
			const { clientGroups } = specificInfo
			const clientGroupsCodes =
				clientGroups.value
					.reduce((a, { code, name }) => Object.assign(a, {
						[code]: name,
					}), {})
			
			const action = ({ memory = {}, value = {} }) => {
				const { medias } = value
				return [
					...memory,
					...medias.map(({ id }) => id),
				]
			}
			
			const condition = ({ value = {} }) => {
				return Object.keys(value)
					.includes('medias')
			}
			
			Object.assign(editor.tags, {
				value:
					Object.keys(editor.tags.value)
						.map(tag => ({
							tag,
							code: tag,
						}))
			})

			state.editor = editor
			state.tutorials =
				data
					.reduce((a, tuto) => {
						let { clientMediaIds, detailsTranslations, info, tags, specificInfo } = tuto
						const { alternatives, clientGroups, guestExclusive, onlyFor, specificLanguages } = specificInfo
						if (isEmpty(detailsTranslations)) {
							detailsTranslations =
								acceptedLanguages.value
									.reduce((a, language) =>
										Object.assign(a, {
											[language]: info
										}), {})
						}
						const inJSON =
							Object.values(detailsTranslations)
								.reduce((a, value) => [
									...a,
									...walkThrough({
										action,
										condition,
										memory: [],
										value,
									})
								], [])
						Object.assign(tuto, {
							addedMediaIds: difference(clientMediaIds, inJSON),
							specificInfo: {
								clientGroups:
									clientGroups
										.map(({ id:code }) => ({
											name: clientGroupsCodes[code],
											code,
										})),
								guestExclusive,
								onlyFor:
									(onlyFor ?? [])
										.map(name => ({
											name,
											code: name,
										})),
								alternatives:
									(alternatives ?? [])
										.map(name => ({
											name,
											code: name,
										})),
								specificLanguages:
									specificLanguages
										.map(name => ({
											name,
											code: name,
										}))
							},
							detailsTranslations,
							tags:
								tags
									.map(tag => ({
										tag,
										code: tag,
									}))
						})
						return [
							...a,
							tuto,
						]
					}, [])
		},
		mutate(state, {
			_extra,
			field,
			func,
			newEdited,
			newParentEdited,
			newParentTab,
			newScrollTop,
			newValue,
			parent,
			path,
			rootState,
			route,
		}) {
			mutate({
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newScrollTop,
				newValue,
				parent,
				path,
				rootState,
				route,
				state,
			})
		},
	},
	actions: {
		addUndo({
			commit,
			rootState,
		}, {
			_extra,
			field,
			func = () => {},
			newEdited = true,
			newParentEdited = true,
			parent,
			path,
			route,
			value:newValue,
		}) {
			const newParentTab =
				parent
					? parent?.tab ?? 0
					: 0
			commit('mutate', {
				_extra,
				field,
				func,
				newEdited,
				newParentEdited,
				newParentTab,
				newValue,
				parent,
				path,
				rootState,
				route,
			})
		},
	},
}
